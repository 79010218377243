import React, { useState } from "react";
import {
  HeadingName,
  HeadingSubtitle,
  HeadingTitle,
  SolutionBoxBody,
  SolutionBoxDescription,
  SolutionBoxIcon,
  SolutionBoxTitle,
  SolutionsBody,
  SolutionsBodyHeader,
  SolutionsBodySection,
  SolutionsBox,
  SolutionsContainer,
} from "../../styles/solutions";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import { Colors } from "../../styles/theme";

interface ISolution {
  id: number;
  title: string;
  description: string;
  icon: string;
  isHighlighted: boolean;
}

const Solutions = () => {
  const solutions: ISolution[] = [
    {
      id: 1,
      title: "Interoperability Platform for EHR Systems",
      description:
        "The BrightVista platform offers configurable connectors that interoperate with your Practice Management and EHR Systems. We offer patients a marketplace experience to the health services, providers, and facilities through a single interface across your enterprise.",
      icon: "/images/features/Ehr_System_Icon.svg",
      isHighlighted: false,
    },
    {
      id: 2,
      title: "Artificial Intelligence",
      description:
        "Our self-service gateway leverages artificial intelligence, interoperability, and natural language understanding giving patients seamless, easy access to health systems and services.",
      icon: "/images/features/Artifical_Inteligence_Icon.svg",
      isHighlighted: true,
    },
    {
      id: 3,
      title: "Our Algorithms",
      description:
        "Our algorithms are configurable to meet the most complex practice needs. BrightVista’s scheduling system can address provider preferences like duration and visit type. We can automate your practice management protocols to meet and exceed your provider expectations.",
      icon: "/images/features/Our_Algorithim_Icon.svg",
      isHighlighted: false,
    },
  ];
  const theme = useTheme();
  const [id, setId] = useState(1);
  const [highlightId, setHighlightId] = useState(1);
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <SolutionsContainer id="bv-difference">
      <SolutionsBody>
        <SolutionsBodyHeader>
          <HeadingName>Discover the BrightVista Difference</HeadingName>
          <HeadingTitle>Welcome to A Better Solution</HeadingTitle>
          <HeadingSubtitle>
            The real magic lies in the fact that BrightVista remains agnostic to
            Electronic Health Record Systems.
          </HeadingSubtitle>
        </SolutionsBodyHeader>
        {matchesMobile ? (
          <>
            <SolutionsBodySection>
              <>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "1rem",
                    transform: "translate(0%, -50%)",
                    padding: "0.5rem",
                    width: "2.25rem",
                    height: "2.25rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    backgroundColor: "#1E425F",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (id > 1) {
                      setId(id - 1);
                    } else {
                      setId(solutions?.length);
                    }
                  }}
                >
                  <NavigateBeforeRoundedIcon
                    sx={{ color: "#fff", width: "1.25rem", height: "1.25rem" }}
                  />
                </Box>
                {solutions?.map((solution: ISolution, index: number) => (
                  <React.Fragment key={solution?.id}>
                    {solution?.id === id && (
                      <SolutionsBox
                        highlight={JSON.stringify(solution?.id === id)}
                      >
                        <SolutionBoxIcon down={JSON.stringify(index % 2 !== 0)}>
                          <img src={solution?.icon} alt="" />
                        </SolutionBoxIcon>
                        <SolutionBoxBody>
                          <SolutionBoxTitle>{solution?.title}</SolutionBoxTitle>
                          <SolutionBoxDescription>
                            {solution?.description}
                          </SolutionBoxDescription>
                        </SolutionBoxBody>
                      </SolutionsBox>
                    )}
                  </React.Fragment>
                ))}
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    right: "1rem",
                    transform: "translate(0%, -50%)",
                    padding: "0.5rem",
                    width: "2.25rem",
                    height: "2.25rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    backgroundColor: "#1E425F",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (id < solutions?.length) {
                      setId(id + 1);
                    } else {
                      setId(1);
                    }
                  }}
                >
                  <NavigateNextRoundedIcon
                    sx={{ color: "#fff", width: "1.25rem", height: "1.25rem" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  {solutions?.map((solution: ISolution, index: number) => (
                    <React.Fragment key={solution?.id + index}>
                      {solution?.id === id ? (
                        <Box
                          sx={{
                            width: "0.5rem",
                            height: "0.25rem",
                            borderRadius: "0.25rem",
                            backgroundColor: Colors.primary,
                            transition: "linear 0.1s",
                          }}
                        ></Box>
                      ) : (
                        <Box
                          sx={{
                            width: "0.25rem",
                            height: "0.25rem",
                            borderRadius: "0.25rem",
                            backgroundColor: Colors.primary,
                            opacity: "0.32",
                          }}
                          onClick={() => setId(solution?.id)}
                        ></Box>
                      )}
                    </React.Fragment>
                  ))}
                </Box>
              </>
            </SolutionsBodySection>
          </>
        ) : (
          <SolutionsBodySection>
            {solutions?.map((solution: ISolution, index: number) => (
              <SolutionsBox
                highlight={JSON.stringify(solution?.id === highlightId)}
                onMouseEnter={() => setHighlightId(solution?.id)}
                key={solution?.id}
              >
                <SolutionBoxIcon down={JSON.stringify(index % 2 !== 0)}>
                  <img src={solution?.icon} alt="" />
                </SolutionBoxIcon>
                <SolutionBoxBody>
                  <SolutionBoxTitle>{solution?.title}</SolutionBoxTitle>
                  <SolutionBoxDescription>
                    {solution?.description}
                  </SolutionBoxDescription>
                </SolutionBoxBody>
              </SolutionsBox>
            ))}
          </SolutionsBodySection>
        )}
      </SolutionsBody>
    </SolutionsContainer>
  );
};

export default Solutions;
