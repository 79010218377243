import { Box, Button, Grid, styled, Typography } from "@mui/material";
import { Colors } from "../theme";

export const RequestDemoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  boxSizing: "border-box",
  width: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export const RequestDemoContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "2rem",
  alignSelf: "stretch",
  boxSizing: "border-box",
  borderRadius: "0.25rem",
}));

export const RequestDemoContent = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "4rem 15.3125rem",
  justifyContent: "space-between",
  alignSelf: "stretch",
  alignItems: "center",
  backgroundColor: theme.palette.primary.main,

  //For 1439 and lower
  [theme.breakpoints.down("lg")]: {
    padding: "4rem 7rem",
  },
  //For 1239 and lower
  [theme.breakpoints.down("md")]: {
    padding: "3rem 2.5rem",
  },

  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    padding: "1.5rem 1rem",
    flexDirection: "column",
    gap: "2rem",
    alignItems: "flex-start",
  },
}));

export const RequestLiveDemoButton = styled(Button)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItmes: "flex-start",
  justifyContent: "center",
  padding: "0.75rem 1.25rem",
  gap: "0.5rem",
  // width: "12.5rem",
  // height: "3rem",
  borderRadius: "0px",
  background: "#378DBD",
  fontSize: "1.125rem",
  fontStyle: "normal",
  color: "#fff",
  fontWeight: 600,
  lineHeight: "1.5rem",
  textTransform: "none",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

  // //For 1239 and lower
  // [theme.breakpoints.down("lg")]: {
  //   width: "12.5rem",
  // },

  // //For 904 and lower
  // [theme.breakpoints.down("md")]: {
  //   width: "100%",
  //   // height: "45%",
  //   alignSelf: "stretch",
  //   flexGrow: 0,
  // },
}));
