import { Stack, Box, useTheme, useMediaQuery } from "@mui/material";
import FeatureCard from "../../components/feature/featureCard";
import FeaturesHomeInfoPages from "../../components/feature/featuresHomeInfoPages";
import FeaturesHomeInfoPagesNew from "../../components/feature/featuresHomeInfoPagesNew";
import { features } from "../../data/features";
import {
  FeatureCardStack,
  FeaturesContainer,
  FeaturesHeadingContainer,
  FeaturesHomeHeaderContent,
  FeaturesHomeHeaderNeutral,
  FeaturesHomeHeaderTitleNew,
  FeaturesHomeHeadTitle,
} from "../../styles/features";

export default function Features() {
  return (
    <FeaturesContainer id="feature-section" className="Features-Container">
      <FeaturesHeadingContainer>
        <Box
          width="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "0.5rem",
            alignSelf: "stretch",
            justifyContent: "center",
          }}
        >
          <Box
            width="100%"
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              alignSelf: "stretch",
              gap: "1.5rem",
            }}
          >
            <FeaturesHomeHeaderNeutral>{`BrightVista Features`}</FeaturesHomeHeaderNeutral>
            <FeaturesHomeHeaderTitleNew>{`A Gateway to Your Enterprise`}</FeaturesHomeHeaderTitleNew>
          </Box>

          <FeaturesHomeHeaderContent>{`BrightVista is a comprehensive gateway that provides multiple patient access capabilities on a single platform. We work with our customers to develop and release capabilities that include patient onboarding, discovering providers, managing dependents, appointment scheduling, managing appointments, insurance verification, cost estimates, and pre-authorizations.`}</FeaturesHomeHeaderContent>
        </Box>
      </FeaturesHeadingContainer>
      <FeatureCardStack>
        {features.map((feature, index) => (
          <FeatureCard key={feature.id} feature={feature} index={index} />
        ))}
      </FeatureCardStack>
    </FeaturesContainer>
  );
}
