import React from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Divider,
  IconButton,
  SwipeableDrawer,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import useNav, { INavDrawer, INavItem } from "../../hooks/useNav";
import {
  AppBarContainer,
  AppBarHeader,
  CloseDrawerBar,
  DrawerItems,
  DrawerList,
  DrawerLogo,
  NavHeading,
  NavHeadingTitle,
  NavItem,
  NavItems,
} from "../../styles/appBar";
import { Colors } from "../../styles/theme";
import { BVButton } from "../../styles/banner";
import useContact from "../../hooks/useContact";
import ContactUsPopup from "../contactUsPopup";
import { Layers } from "../icons/Layers";

interface AppBarProps {
  matches: boolean;
}

export default function AppBarMobile({ matches }: AppBarProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { showNav, activeNav, navDrawer, handleActiveNav, handleToggleNav } =
    useNav();
  const {
    showContactPopup,
    contactUsPopupStatus,
    handleContactPopup,
    handleSendContactInfo,
  } = useContact();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <AppBarContainer className="AppBar-Container" position="sticky">
      <AppBarHeader
        src="/images/BVLogo.svg"
        onClick={handleLogoClick}
      ></AppBarHeader>
      <IconButton>
        <MenuRoundedIcon
          sx={{ color: "#344054" }}
          onClick={() => handleToggleNav(true)}
        />
      </IconButton>
      <SwipeableDrawer
        anchor={"right"}
        open={showNav}
        onClose={() => handleToggleNav(false)}
        onOpen={() => handleToggleNav(true)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "19.44rem",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            }
          },
        }}
      >
        <Box sx={{ display: "flex", flex: 1 }}>
          <DrawerItems>
            <DrawerLogo
              src="/images/Logo_black.svg"
              onClick={() => {}}
            ></DrawerLogo>
            <DrawerList>
              {navDrawer?.map((nav: INavDrawer) => (
                <React.Fragment key={nav?.id}>
                  <NavHeading
                    onClick={() => {
                      if (activeNav === nav?.id) {
                        handleActiveNav(-1);
                      } else {
                        handleActiveNav(nav?.id);
                      }
                    }}
                  >
                    <Layers
                      sx={{
                        width: "24px",
                        height: "24px",
                        marginRight: "0.5rem",
                      }}
                    ></Layers>
                    <NavHeadingTitle>{nav?.heading}</NavHeadingTitle>
                    <Box
                      sx={{
                        width: "1.5rem",
                        height: "1.5rem",
                        marginLeft: "0.5rem",
                      }}
                    >
                      {activeNav === nav?.id ? (
                        <ExpandLessRoundedIcon />
                      ) : (
                        <ExpandMoreRoundedIcon />
                      )}
                    </Box>
                  </NavHeading>
                  {activeNav === nav?.id && (
                    <NavItems>
                      {nav?.items?.map((item: INavItem) => (
                        <NavItem
                          key={item?.id}
                          onClick={() => {
                            handleToggleNav(false);
                            setTimeout(() => {
                              const targetElement = document.getElementById(
                                item?.url
                              );
                              if (targetElement) {
                                targetElement.scrollIntoView({
                                  behavior: "smooth",
                                });
                                // handleActiveNav(1);
                              }
                            }, 0);
                          }}
                        >
                          {item?.title}
                        </NavItem>
                      ))}
                    </NavItems>
                  )}
                </React.Fragment>
              ))}
            </DrawerList>
            <Divider
              sx={{
                color: Colors.gray_200,
                margin: "2rem 1rem",
              }}
            />
            <Box
              sx={{
                width: "100%",
                padding: "0rem 1.5rem",
                boxSizing: "border-box",
              }}
            >
              <BVButton
                variant="contained"
                sx={{
                  width: "100%",
                  padding: "0.5rem 1rem",
                  borderRadius: "0rem",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "125%",
                  backgroundColor: Colors.primary,
                }}
                disabled={false}
                onClick={() => {
                  handleContactPopup(true);
                  handleToggleNav(false);
                }}
              >
                Contact Us
              </BVButton>
            </Box>
          </DrawerItems>
          <CloseDrawerBar>
            <CloseRoundedIcon
              sx={{
                color: "#fff",
                position: "absolute",
                top: "1.5rem",
                right: "1rem",
                cursor: "pointer",
              }}
              onClick={() => {
                handleToggleNav(false);
                handleActiveNav(1);
              }}
            />
          </CloseDrawerBar>
        </Box>
      </SwipeableDrawer>
      <ContactUsPopup
        status={contactUsPopupStatus}
        showContactPopup={showContactPopup}
        handleContactPopup={handleContactPopup}
        handleSendContactInfo={handleSendContactInfo}
      />
    </AppBarContainer>
  );
}
