import axios, { AxiosRequestConfig } from "axios";
import { IClientOperationResponse } from "./types";

// const URL = process.env.REACT_APP_API_ENDPOINT;
const OPEN_URL =
  "https://skemu2m0c8.execute-api.us-east-1.amazonaws.com/heka-network-support-api";
// const url = "https://brightvista-api.dev-ioteractions.org";

export class Client {
  private request(config: AxiosRequestConfig): IClientOperationResponse {
    let cancelTokenSource = axios.CancelToken.source();
    config.cancelToken = cancelTokenSource.token;
    return {
      axiosPromise: axios(config).catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log("Request canceled", thrown.message);
        } else {
          // handle error
          throw thrown;
        }
      }),
      cancelToken: cancelTokenSource,
    };
  }

  userInfoPost(payload: object, endpoint: string): IClientOperationResponse {
    let config: AxiosRequestConfig = {
      method: "POST",
      url: `${OPEN_URL}${endpoint}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    };
    let request = this.request(config);
    return request;
  }
}
