import { Box, Button, Stack, styled, Theme, Typography } from "@mui/material";
import { Colors } from "../theme";

export const OurStorysHomeContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "75.05%",
  boxSizing: "border-box",
  padding: "0rem 2rem",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "2rem",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    width: "auto",
  },

  //For 960 and lower
  [theme.breakpoints.down("md")]: {
    alignSelf: "stretch",
  },
  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    padding: "0px",
  },
}));

export const OurStorysHomeContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2.5rem",
  alignSelf: "stretch",
}));

export const OurStorysHomeHeaderContainer = styled(Box)(({ theme }) => ({
  width: "63.15%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1.25rem",

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    width: "auto",
    alignSelf: "stretch",
  },
}));

export const OurStorysHomeHeaderContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "0.75rem",
  alignSelf: "stretch",
}));

export const OurStoryHomeHeaderNeutralContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.65rem",
  padding: "0.25rem 1rem",
  boxSizing: "border-box",
  borderRadius: "3.125rem",
  background: "rgba(30, 66, 95, 0.10)",
}));

export const OurStoryHomeHeaderNeutral = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "1rem",
  lineHeight: "normal",
  color: theme.palette.primary.main,
}));

export const OurStoryHomeHeaderTitle = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 600,
  textAlign: "center",
  fontSize: "2.625rem",
  lineHeight: "2.75rem",

  //For 1439 and lower
  [theme.breakpoints.down("lg")]: {
    lineHeight: "3.28125rem",
  },

  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.25rem",
    lineHeight: "2.8125rem",
  },
  color: theme.palette.primary.main,
}));

export const OurStoryHomeText = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "150%",
  /* or 28px */

  width: "100%",

  //For 1239 and lower
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },

  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    textAlign: "unset",
  },

  color: Colors.text_secondary,
  opacity: 0.8,
  flex: "none",
  order: 1,
  alignSelf: "stretch",
  flexGrow: 0,
}));

export const OurStoryHomeMoreDetailsButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0.75rem 1.25rem",
  boxSizing: "border-box",
  gap: "0.5rem",

  border: "1px solid  #D0D5DD",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  borderRadius: "0px",
  textTransform: "none",
  fontStyle: "normal",
  fontSize: "1.125rem",
  fontWeight: 600,
  lineHeight: "1.5rem",
  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    width: "81.63%",
  },
})) as typeof Button;

export const OurStorysHomeImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "75.05%",
  padding: "0rem 2rem",
  boxSizing: "border-box",
  flexDirection: "column",
  alignItems: "flex-start",

  gap: "4rem",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    width: "87.09%",
  },

  //For 960 and lower
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: 0,
  },

  // [theme.breakpoints.down("sm")]: {
  //   width: "100%",
  // },
}));

export const OurStorysHomeImageContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "2rem",
  alignSelf: "stretch",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "unset",
    alignItems: "center",
  },
}));

export const OurStorysHomeImageMember = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "1.5rem",
  width: "17.5rem",

  //For 960 and lower
  [theme.breakpoints.down("md")]: {
    flex: "1 0 0",
    alignSelf: "stretch",
  },

  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    alignSelf: "unset",
  },
}));

export const OurStorysHomeImage = styled(Box)(
  ({ theme, backgroundColor }: { theme: Theme; backgroundColor: string }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    background: backgroundColor,
    alignItems: "center",
    boxSizing: "border-box",

    alignSelf: "stretch",
  })
);

export const OurStorysHomeMemberDetails = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  boxSizing: "border-box",
  gap: "1.5rem",
  alignSelf: "stretch",

  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
  },
}));

export const OurStorysHomeMemberText = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  boxSizing: "border-box",
  gap: "0.25rem",
  alignSelf: "stretch",

  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
  },
}));

export const OurStorysHomeMemberMoreInfo = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0.75rem 1.25rem",
  boxSizing: "border-box",
  gap: "0.5rem",

  border: "1px solid  #D0D5DD",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  borderRadius: "0px",
  textTransform: "none",
  fontStyle: "normal",
  fontSize: "1.125rem",
  fontWeight: 600,
  lineHeight: "1.5rem",
  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    width: "81.63%",
  },
})) as typeof Button;

export const OurStorysHomeMemberName = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "1.5rem",
  lineHeight: "1.875",
  color: theme.palette.primary.main,
  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

export const OurStorysHomeMemberRole = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "1.25rem",
  lineHeight: "1.75rem",
  color: "#378DBD",

  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

export const OurStorysHomeImageNew = styled(Box)(
  ({ theme, backgroundColor }: { theme: Theme; backgroundColor: string }) => ({
    display: "flex",
    width: "100%",
    background: backgroundColor,
    // background: "#378DBD",
    borderRadius: "35px",
    boxSizing: "border-box",
  })
);

//OurStorysHomeContentContainer
export const OurStoryContentContainer = styled(OurStorysHomeContentContainer)(
  ({ theme }) => ({
    justifyContent: "flex-start",
    alignSelf: "center",
    position: "relative",
    "&:before ": {
      position: "absolute",
      width: "100vw",
      height: "90%",
      content: '""',
      backgroundColor: Colors.body_bg,
      left: "-30.5%",
    },

    // For 1439 and lower
    [theme.breakpoints.down("xl")]: {
      "&:before ": {
        left: "-10.5%",
      },
    },

    //For 1239 and lower
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: "80px",
      width: "100%",

      "&:before ": {
        height: "65%",
        left: "0%",
      },
    },
    //For 904 and lower
    [theme.breakpoints.down("md")]: {
      "&:before ": {
        height: "85%",
      },
    },
  })
);

export const OurStoryContentHeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "49%",
  alignItems: "center",
  zIndex: 2,
  gap: "12px",
  // For 1439 and lower
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    // gap: "24px",
    width: "62.2%",
  },
  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    width: "91.2%",
    alignItems: "flex-start",
    textAlign: "unset",
  },
}));

export const OurStoryMainContentHeaderContainer = styled(
  OurStorysHomeContentContainer
)(({ theme }) => ({
  flexDirection: "column",
  justifyContent: "center",
  alignSelf: "center",
  gap: "12px",
  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    width: "62.2%",
    gap: "12px",
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    width: "91.2%",
    alignItems: "flex-start",
    gap: "12px",
  },
}));

export const OurStoryTeamContainerStack = styled(Stack)(({ theme }) => ({
  width: "62.5%",
  alignSelf: "center",
  marginTop: "60px",
  //For 1439 and lower

  [theme.breakpoints.down("xl")]: {
    width: "82.4%",
  },

  "&>*:nth-of-type(even)": {
    position: "relative",
    ".Content-Container": {
      order: 0,
      ".OurStory-Text-Container": {
        order: 1,
      },
    },

    ".OurStory-Index": {
      order: 1,
    },
  },
}));

export const OurStorysHeaderImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "50%",
  zIndex: 2,

  //For 1439 and lower
  [theme.breakpoints.down("xl")]: {
    width: "56%",
  },

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    justifyContent: "center",
  },
}));

export const OurStorysImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  width: "24.3%",
  height: "73%",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    alignSelf: "center",
    width: "42.5%",
    flex: "none",
    order: 0,
    flexGrow: 0,
    maxWidth: "242px",
    maxHeight: "305px",
  },

  [theme.breakpoints.down("md")]: {
    alignSelf: "unset",
    width: "74.5%",
  },
}));

export const CreativeTeamNeutral = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "156%",
  /* or 28px */
  textTransform: "uppercase",
  letterSpacing: "0.08em",
  width: "100%",
  textAlign: "center",
  // height: "28px",
  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    lineHeight: "200%",
    fontSize: "14px",
    textAlign: "unset",
  },

  color: Colors.text_Neutral,
  opacity: 0.9,
  flex: "none",
  order: 0,
  flexGrow: 0,
}));

export const CreativeTeamHeader = styled(Typography)(({ theme }) => ({
  fontSize: "48px",
  lineHeight: "125%",
  fontWeight: 700,
  /* or 50px */
  textTransform: "none",
  textAlign: "center",
  width: "63%",
  alignSelf: "center",
  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    width: "85%",
    fontSize: "40px",
    alignItems: "center",
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    width: "90%",
    fontSize: "36px",
    alignItems: "flex-start",
    textAlign: "unset",
    alignSelf: "unset",
  },

  display: "flex",
  color: Colors.primary,
}));

export const CreativeTeamIndexTitle = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "96px",
  lineHeight: "125%",
  width: "14%",
  /* or 28px */
  padding: "2px 0px",
  textAlign: "center",
  zIndex: 2,

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    fontSize: "40px",
    width: "100%",
    textAlign: "center",
    alignSelf: "stretch",
    padding: "0px",
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    textAlign: "unset",
  },

  color: Colors.text_Neutral,
  // flex: "none",
  // order: 1,
  // flexGrow: 0,
}));

export const CreativeTeamMemberName = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "40px",
  lineHeight: "125%",
  /* or 28px */

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    fontSize: "32px",
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
    alignSelf: "flex-start",
  },
  flex: "none",
  color: Colors.neutral_800,
  order: 0,
}));

export const CreativeTeamMemberDesignation = styled(Typography)(
  ({ theme }) => ({
    fontWeight: 400,
    fontSize: "28px",
    lineHeight: "136%",
    opacity: 0.8,
    /* or 28px */

    //For 1239 and lower
    [theme.breakpoints.down("lg")]: {
      // height: "calc(100% - 50px)",
      // fontSize: "18px",
    },

    //For 904 and lower
    [theme.breakpoints.down("md")]: {
      alignSelf: "flex-start",
      fontSize: "20px",
      lineHeight: "150%",
    },
    flex: "none",
    color: Colors.neutral_700,
    order: 0,
  })
);

export const CreativeTeamMemberDescription = styled(Typography)(
  ({ theme }) => ({
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "175%",
    opacity: 0.8,
    /* or 28px */

    //For 1239 and lower
    [theme.breakpoints.down("lg")]: {
      // height: "calc(100% - 50px)",
      // fontSize: "18px",
    },

    //For 904 and lower
    [theme.breakpoints.down("md")]: {
      // height: "calc(100% - 45px)",
    },
    flex: "none",
    color: Colors.neutral_900,
    order: 0,
    flexGrow: 1,
  })
);

export const OurStoryHeader = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  lineHeight: "156%",
  fontWeight: 700,
  /* or 50px */
  textTransform: "uppercase",
  letterSpacing: "0.08em",
  alignSelf: "flex-start",
  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    alignSelf: "center",
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    alignSelf: "flex-start",
    opacity: "0.9",
    fontSize: "14px",
    lineHeight: "200%",
  },

  display: "flex",
  color: Colors.primary,
}));

export const OurStoryHeaderTitle = styled(Typography)(({ theme }) => ({
  fontSize: "62px",
  lineHeight: "125%",
  fontWeight: 700,
  /* or 50px */
  textTransform: "none",
  alignSelf: "flex-start",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    fontSize: "48px",
    alignSelf: "center",
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    fontSize: "40px",
    alignSelf: "flex-start",
  },

  display: "flex",
  color: Colors.primary,
}));

export const CreativeTeamPagesContainer = styled(Box)(({ theme }) => ({
  marginTop: "30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0px",
  gap: "30px",
  width: "100%",

  flex: "none",
  order: 0,
  flexGrow: 0,

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    alignItems: "flex-start",
    alignSelf: "center",
    width: "91.2%",
  },

  ".Content-Container": {
    flexDirection: "column",
    order: 0,
    ".OurStory-Text-Container": {
      order: 1,
    },
  },
}));

export const CreativeTeamPagesIndex = styled(Button)(
  ({ theme, selected }: { theme: Theme; selected: boolean }) => ({
    fontStyle: "normal",
    minWidth: "unset",
    fontSize: "18px",
    lineHeight: "150%",

    color: selected ? Colors.primary : Colors.text_Neutral,
    opacity: 0.9,
    flex: "none",
    order: 0,
    flexGrow: 0,
  })
);

export const OurStoryTextContainer = styled(Box)(({ theme }) => ({
  width: "59%",
  // height: "66%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  gap: "32px",
  flex: "none",
  order: 0,
  flexGrow: 0,
  padding: "50px 0px 0px 30px",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    width: "91.2%",
    alignSelf: "center",
    textAlign: "center",
    padding: "50px 0px 0px 0px",
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    width: "100%",
    textAlign: "unset",
    paddingBottom: "30px",
  },
}));

export const OurStoryTextMemberNameContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  // height: "66%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  gap: "8px",
  flex: "none",
  order: 0,
  flexGrow: 0,

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    alignItems: "center",
  },
}));
