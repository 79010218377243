import { Box, Grid, styled, Typography } from "@mui/material";
import { Colors } from "../theme";

export const FooterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  boxSizing: "border-box",
  width: "100%",
  padding: "4rem 0rem 3rem 0rem",
  flexDirection: "column",
  background: "#F7F9FC",
  alignItems: "center",
  gap: "4rem",
}));

export const FooterContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "75.05%",
  padding: "0rem 2rem",
  flexDirection: "column",
  alignItems: "center",
  gap: "1.5rem",
  boxSizing: "border-box",
  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    alignSelf: "stretch",
  },

  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    padding: "0rem 1rem",
  },
}));

export const FooterContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // width: "80rem",
  alignSelf: "stretch",
  alignItems: "center",
  gap: "2rem",
}));

export const FooterLinks = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: " center",
  // width: "80rem",
  alignSelf: "stretch",
  alignItems: "center",
  gap: "2rem",

  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const GridVisitUs = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  gap: "32px",
  width: "15%",
  height: "100%",

  //For 1239 and lower
  [theme.breakpoints.down("md")]: {
    gap: "27px",
    height: "40%",
    width: "35%",
    alignSelf: "flex-start",
  },

  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    gap: "27px",
    width: "75%",
    alignSelf: "flex-start",
  },
}));

export const GridInTouch = styled(GridVisitUs)(({ theme }) => ({
  width: "20.5%",

  //For 1239 and lower
  [theme.breakpoints.down("md")]: {
    height: "30%",
    width: "50.5%",
  },

  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    height: "30%",
  },
}));

export const CopyRightContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "75.05%",
  padding: "0rem 2rem",
  flexDirection: "column",
  alignItems: "center",
  gap: "2rem",
  boxSizing: "border-box",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    alignSelf: "stretch",
  },

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    padding: "0rem 1rem",
  },
}));

export const CopyRightContentContainer = styled(Box)(({ theme }) => ({
  // padding-top: 32px;
  justifyContent: "center",
  alignItems: "center",

  display: "flex",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    alignSelf: "stretch",
  },

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {},
}));

export const CopyrightContent = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "1.125rem",
  lineHeight: "150%",
  color: "#667085",
  textAlign: "center",

  //For 960 and lower
  [theme.breakpoints.down("md")]: {},
  //For 499 and lower
  [theme.breakpoints.down("sm")]: {},
}));
