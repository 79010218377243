import { useMediaQuery, useTheme } from "@mui/material";
import AppBarDeskTop from "./appBarDesktop";
import AppBarMobile from "./appBarMobile";

export default function AppBar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return matches ? (
    <AppBarDeskTop matches={matches} />
  ) : (
    <AppBarMobile matches={matches} />
  );
}
