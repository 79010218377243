import { Box, Typography, useTheme } from "@mui/material";
import {
  TestimonialList,
  TestimonialsListContainer,
} from "../../styles/userTestimonial";
import TestimonialCard from "../testimonialCard";

export default function UserTestimonial() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        [theme.breakpoints.down("sm")]: {
          height: "75rem",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0% 14.4%",
          flexShrink: 0,
          boxSizing: "border-box",
          backgroundImage: `linear-gradient(rgba(30, 66, 95,0.9), rgb(30, 66, 95,0.9)), url(/images/testimonals/background_image.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          [theme.breakpoints.down("lg")]: {
            padding: "0% 9%",
          },
          [theme.breakpoints.down("md")]: {
            padding: "0% 4%",
          },
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            gap: "4rem",
            paddingTop: "2.5rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          },
        }}
      >
        <Box
          sx={{
            width: "31.6%",
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            boxSizing: "border-box",
            marginTop: "212px",
            marginBottom: "212px",
            [theme.breakpoints.down("lg")]: {
              width: "38%",
              marginTop: "252px",
              marginBottom: "252px",
            },
            [theme.breakpoints.down("md")]: {
              width: "43.7%",
            },
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              marginTop: "42px",
              marginBottom: "64px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.625rem",
              width: "max-content",
              padding: "0.25rem 1rem",
              boxSizing: "border-box",
              borderRadius: "3.125rem",
              background: "rgba(255, 255, 255, 0.10)",
            }}
          >
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "1rem",
                lineHeight: "normal",
                color: "#F7F9FC",
              }}
            >{`Testimonials`}</Typography>
          </Box>
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "3rem",
              lineHeight: "125%",
              color: "#FFF",
              width: "100%",
            }}
          >{`See What Patients Say About Our Experience`}</Typography>
          <Typography
            sx={{
              width: "100%",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "1.25rem",
              lineHeight: "120%",
              color: "#F7F9FC",
            }}
          >{`Our goal is to create a better digital healthcare experience. We listen to what patients are saying and believe feedback is vital in the evolution of our enhanced access platform.`}</Typography>
        </Box>
        <TestimonialsListContainer className="TestimonialsList">
          <TestimonialList>
            <TestimonialCard
              testimonial={"“Thanks. This is easier than the phone system”"}
              rating={5}
            />
            <TestimonialCard
              testimonial={
                "“Love the online scheduling! Super easy and appreciate being able to do it at my convenience when I could more carefully look at our schedules to find the best day and time. A win for CMM!”"
              }
              rating={5}
            />
          </TestimonialList>
          <TestimonialList>
            <TestimonialCard
              testimonial={"“I just signed up for future use. Very easy”"}
              rating={5}
            />
            <TestimonialCard
              testimonial={"“Very easy to navigate and confirm appointment.”"}
              rating={5}
            />
          </TestimonialList>
        </TestimonialsListContainer>
      </Box>
    </Box>
  );
}
