import { useEffect, useState } from "react";
import { ContactInfo } from "../components/contactUsPopup";
import useUpdateContactInfo from "./useUpdateContactInfo";

const useLiveDemo = () => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [liveDemoPopupStatus, setLiveDemoPopupStatus] = useState<
    "request" | "success" | "fail"
  >("request");
  const { addContactDetails, contactUsApiUpdates } = useUpdateContactInfo();
  const handleOpenPopup = (bool: boolean) => {
    setOpenPopup(bool);
    if (!bool) {
      setLiveDemoPopupStatus("request");
    }
  };

  const handleSendContactInfo = (contactData: ContactInfo) => {
    if (contactData.email) {
      addContactDetails(contactData);
    }
  };

  useEffect(() => {
    if (
      contactUsApiUpdates.loading === false &&
      contactUsApiUpdates.data &&
      Object.keys(contactUsApiUpdates.data).length
    ) {
      setLiveDemoPopupStatus("success");
    } else if (
      contactUsApiUpdates.loading === false &&
      contactUsApiUpdates.error &&
      Object.keys(contactUsApiUpdates.error).length
    ) {
      setLiveDemoPopupStatus("fail");
    }
  }, [contactUsApiUpdates]);
  return {
    liveDemoPopupStatus,
    openPopup,
    handleSendContactInfo,
    handleOpenPopup,
  };
};

export default useLiveDemo;
