import { Box, Button, List, ListItemText, styled } from "@mui/material";
import { Colors } from "../theme";

type NavListProps = {
  type: string;
};

export const AppBarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#F7F9FC",
  width: "100%",
  height: "5rem",
  top: 0,
  padding: "0.5rem 5.83%",
  justifyContent: "space-around",
  flexShrink: "0",
  zIndex: 999,
  boxSizing: "border-box",

  [theme.breakpoints.down("lg")]: {
    padding: "0.5rem 0.5%",
  },

  [theme.breakpoints.down("md")]: {
    justifyContent: "space-between",
    padding: "0.5rem 2%",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "0rem 0.75rem",
  },
}));

// export const AppBarContentContainer = styled(Box)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "space-between",
//   alignItems: "center",
//   padding: "8px 0px",
//   gap: "130px",

//   width: "63%",
//   height: "100px",

//   //For 1439 and lower
//   [theme.breakpoints.down("xl")]: {
//     width: "82.4%",
//   },

//   //For 1239 and lower
//   [theme.breakpoints.down("lg")]: {
//     width: "94.6%",
//   },

//   //For 904 and lower
//   [theme.breakpoints.down("md")]: {
//     width: "91.3%",
//     gap: "unset",
//   },
// }));

export const AppBarHeader = styled(`img`)(({ theme }) => ({
  flexShrink: 0,
  // cursor: "pointer",
}));

export const NavList = styled(List)(({ type }: NavListProps) => ({
  display: type === "row" ? "flex" : "block",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: "0.5rem 2rem",
  gap: "1.5rem",
  borderRadius: "3.125rem",
}));

export const NavListItemText = styled(ListItemText)(() => ({
  display: "flex",
  alignItems: "flex-start",
  padding: "0.5rem 0rem",
  gap: "0.625rem",
  borderRadius: "0.375rem",

  "& span": {
    lineHeight: "125%",
    color: "#4A5468",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 600,
  },
}));

export const AppBarButton = styled(Button)(() => ({
  display: "flex",
  padding: "0.5rem 1rem",
  gap: "0.635rem",
  borderRadius: "0px",
  textTransform: "none",
  fontStyle: "normal",
  fontSize: "1rem",
  lineHeight: "125%",
  fontWeight: 700,
  alignSelf: "center",
}));

export const DrawerItems = styled(Box)(({ theme }) => ({
  width: "15.44rem",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const CloseDrawerBar = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.primary,
  color: Colors.white,
  width: "4rem",
  height: "100%",
  position: "relative",
}));

export const DrawerLogo = styled("img")(({ theme }) => ({
  marginTop: "1rem",
  marginBottom: "1.25rem",
  height: "2.25rem",
  marginLeft: "1rem",
}));

export const DrawerList = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "0rem 0.5rem",
  boxSizing: "border-box",
  // border: "1px solid black",
}));

export const NavHeading = styled(Box)(({ theme }) => ({
  padding: "0.5rem 0.75rem",
  marginBottom: "0.5rem",
  display: "flex",
  cursor: "pointer",
}));

export const NavHeadingLogo = styled(Box)(({ theme }) => ({
  width: "1.5rem",
  height: "1.5rem",
  marginRight: "0.75rem",
  backgroundColor: Colors.gray_200,
  textAlign: "center",
}));

export const NavHeadingTitle = styled(Box)(({ theme }) => ({
  width: "100%",
  color: Colors.neutral_700,
  fontSize: "1.25rem",
  fontWeight: "700",
  lineHeight: "1.5rem",
}));

export const NavItems = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",
}));

export const NavItem = styled(Box)(({ theme }) => ({
  height: "2.25rem",
  paddingLeft: "3rem",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  color: Colors.neutral_700,
  fontSize: "0.9rem", //todo - required 1.125rem
  fontWeight: "600",
  lineHeight: "1.5rem",
  // border: "0.5px solid gray",
  borderRadius: "0.375rem",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: Colors.neutral_200,
  },
}));
