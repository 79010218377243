import {
  OurMissonDescription,
  OurMissonTitle,
  OurMissonContainer,
  OurMissonContentContainer,
  OurMissonTextFrame,
} from "../../styles/misson";

export default function OurMisson() {
  return (
    <OurMissonContainer className="OurMisson-Container">
      <OurMissonContentContainer>
        <OurMissonTextFrame>
          <OurMissonTitle>{`Our mission`}</OurMissonTitle>
          <OurMissonDescription>
            {`"To provide your patients with a Unified and User-friendly experience by offering a
single, all-encompassing platform. Through BrightVista Gateway, your patients gain easy access
to a wide array of providers, services, and vital information across your entire enterprise."`}
          </OurMissonDescription>
        </OurMissonTextFrame>
      </OurMissonContentContainer>
    </OurMissonContainer>
  );
}
