import React from "react";
import {
  BenefitsChip,
  BenefitsDescription,
  BenefitsImage,
  BenefitsImageContainer,
  BenefitsInfoContainer,
  BenefitsSection,
  BenefitsTemplate,
  BenefitsTitle,
  BenifitsContainer,
} from "../../styles/benefits";

const Benefits = () => {
  return (
    <BenefitsSection id="benefits">
      <BenifitsContainer>
        <BenefitsInfoContainer>
          <BenefitsChip label={"Benefits"} />
          <BenefitsTitle>
            Experience the BrightVista Benefit, Elevating Your Bottom Line!
          </BenefitsTitle>
          <BenefitsDescription>
            The BrightVista gateway offers a unique opportunity to bolster both
            your business's revenue and overall performance. It provides your
            enterprise with a robust platform to foster growth through a
            self-reinforcing cycle of capabilities. This cycle not only
            strengthens trust and loyalty among your patients and providers but
            also streamlines operations and enhancing profitability.
          </BenefitsDescription>
          <BenefitsTemplate>
            At BrightVista, we are confident we can reduce your cost of manual
            scheduling and patient acquisition by 50%.
          </BenefitsTemplate>
        </BenefitsInfoContainer>
        <BenefitsImageContainer>
          <BenefitsImage src="/images/benefits/Benefits.png" />
        </BenefitsImageContainer>
      </BenifitsContainer>
    </BenefitsSection>
  );
};

export default Benefits;
