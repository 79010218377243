import { Box, styled } from "@mui/material";

export const TestimonialsListContainer = styled(Box)(({ theme }) => ({
  width: "46.4%",
  height: "100%",
  display: "flex",
  gap: "1.5rem",
  justifyContent: "space-between",
  alignItems: "center",
  overflowY: "auto",
  boxSizing: "border-box",

  [theme.breakpoints.down("lg")]: {
    width: "56%",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "1.5rem",
    width: "35.2%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const TestimonialList = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem",
}));
