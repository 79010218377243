import {
  Box,
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { Colors } from "../../styles/theme";
import {
  CopyRightContainer,
  CopyRightContentContainer,
  CopyrightContent,
  FooterContainer,
  FooterContent,
  FooterContentContainer,
  FooterLinks,
  GridInTouch,
  GridVisitUs,
} from "../../styles/footer";

export default function Footer() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.between("md", "lg"));
  return (
    <FooterContainer className="Footer-Container">
      {/* {matches ? (
        <GridContainer container>
          <Grid
            item
            sx={{ alignSelf: "flex-start", width: "163px", height: "36px" }}
          >
            <img
              style={{
                width: "163px",
                height: "36px",
                flex: "none",
                order: 0,
                flexGrow: 0,
              }}
              src="/images/Logo_black.svg"
              alt="logo"
            />
          </Grid>
          <Grid container sx={{ gap: "75px" }}>
            <GridVisitUs item>
              <Typography
                sx={{
                  width: "180px",
                  height: "30px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "125%",
                  color: Colors.white,
                }}
              >
                {`Visit Us`}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  padding: "0px",
                  gap: "8px",
                  width: "90%",
                  height: "25.7%",
                }}
              >
                <LanguageOutlinedIcon
                  sx={{ color: Colors.white, width: "24px", height: "24px" }}
                />
                <Typography
                  sx={{
                    width: "calc(100% - 24px)",
                    height: "100%",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "150%",
                    color: Colors.white,
                  }}
                >
                  {`BrightVista.com`}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  padding: "0px",
                  gap: "8px",
                  width: "100%",
                  height: "51.4%",
                }}
              >
                <LocationOnOutlinedIcon
                  sx={{ color: Colors.white, width: "24px", height: "24px" }}
                />
                <Typography
                  sx={{
                    width: "calc(100% - 24px)",
                    height: "100%",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "150%",
                    color: Colors.white,
                  }}
                >
                  {`San Francisco, CA
                94104, US 🌦️ 12°`}
                </Typography>
              </Box>
            </GridVisitUs>
            <GridInTouch item>
              <Typography
                sx={{
                  width: "180px",
                  height: "30px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "125%",
                  color: Colors.white,
                }}
              >
                {`Get In Touch`}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  padding: "0px",
                  gap: "8px",
                  width: "100%",
                  height: "25.7%",
                }}
              >
                <EmailOutlined
                  sx={{ color: Colors.white, width: "24px", height: "24px" }}
                />
                <Typography
                  sx={{
                    width: "calc(100% - 24px)",
                    height: "100%",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "150%",
                    color: Colors.white,
                  }}
                >
                  {`Support@BrightVista.com`}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  padding: "0px",
                  gap: "8px",
                  width: "100%",
                  height: "51.4%",
                }}
              >
                <LocalPhoneOutlinedIcon
                  sx={{ color: Colors.white, width: "24px", height: "24px" }}
                />
                <Typography
                  sx={{
                    width: "calc(100% - 24px)",
                    height: "100%",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "150%",
                    color: Colors.white,
                  }}
                >
                  {`+1-202-555-0144`}
                </Typography>
              </Box>
            </GridInTouch>
          </Grid>
        </GridContainer>
      ) : (
        <GridContainer container>
          <Grid item sx={{ alignSelf: "flex-start" }}>
            <img
              style={{
                width: "163px",
                height: "36px",
                flex: "none",
                order: 0,
                flexGrow: 0,
              }}
              src="/images/Logo_white.svg"
              alt="logo"
            />
          </Grid>
          <GridVisitUs item>
            <Typography
              sx={{
                width: "180px",
                height: "30px",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "24px",
                lineHeight: "125%",
                color: Colors.white,
              }}
            >
              {`Visit Us`}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "0px",
                gap: "8px",
                width: "90%",
                height: "25.7%",
              }}
            >
              <LanguageOutlinedIcon
                sx={{ color: Colors.white, width: "24px", height: "24px" }}
              />
              <Typography
                sx={{
                  width: "calc(100% - 24px)",
                  height: "100%",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "150%",
                  color: Colors.white,
                }}
              >
                {`BrightVista.com`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "0px",
                gap: "8px",
                width: "100%",
                height: "51.4%",
              }}
            >
              <LocationOnOutlinedIcon
                sx={{ color: Colors.white, width: "24px", height: "24px" }}
              />
              <Typography
                sx={{
                  width: "calc(100% - 24px)",
                  height: "100%",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "150%",
                  color: Colors.white,
                }}
              >
                {`300 E. Pine St. Seattle WA 98122°`}
              </Typography>
            </Box>
          </GridVisitUs>
          <GridInTouch item>
            <Typography
              sx={{
                width: "180px",
                height: "30px",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "24px",
                lineHeight: "125%",
                color: Colors.white,
              }}
            >
              {`Get In Touch`}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "0px",
                gap: "8px",
                width: "100%",
                height: "25.7%",
              }}
            >
              <EmailOutlined
                sx={{ color: Colors.white, width: "24px", height: "24px" }}
              />
              <Typography
                sx={{
                  width: "calc(100% - 24px)",
                  height: "100%",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "150%",
                  color: Colors.white,
                }}
              >
                {`Support@BrightVista.com`}
              </Typography>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "0px",
                gap: "8px",
                width: "100%",
                height: "51.4%",
              }}
            >
              <LocalPhoneOutlinedIcon
                sx={{ color: Colors.white, width: "24px", height: "24px" }}
              />
              <Typography
                sx={{
                  width: "calc(100% - 24px)",
                  height: "100%",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "150%",
                  color: Colors.white,
                }}
              >
                {`+1-202-555-0144`}
              </Typography>
            </Box> 
          </GridInTouch>
        </GridContainer>
      )}
     
     
         <CopyrightContent>
          {`Get Started    -   Privacy Policy  -   Terms and Services`}
        </CopyrightContent>
         */}
      <FooterContentContainer>
        <FooterContent>
          <Box>
            <img
              style={{
                flex: "none",
                order: 0,
                flexGrow: 0,
              }}
              src="/images/Logo_black.svg"
              alt="logo"
            />
          </Box>
          <FooterLinks>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "1.125rem",
                lineHeight: "1.5rem",
                color: Colors.gray,
              }}
            >
              {`300 E. Pine St. Seattle WA 98122°`}
            </Typography>

            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "1.125rem",
                lineHeight: "1.5rem",
                color: Colors.gray,
              }}
            >
              {`Support@BrightVista.com`}
            </Typography>
          </FooterLinks>
        </FooterContent>
      </FooterContentContainer>

      <Divider sx={{ width: "100%", borderColor: "#EAECF0" }} />
      <CopyRightContainer>
        <CopyRightContentContainer>
          <CopyrightContent>
            {`Copyright © 2023 All rights reserved by BrightVista`}
          </CopyrightContent>
        </CopyRightContentContainer>
      </CopyRightContainer>
    </FooterContainer>
  );
}
