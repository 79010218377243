import { SvgIcon, SvgIconProps } from "@mui/material";
export const QuoteLeft = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <g opacity="0.48">
        <path
          d="M14 10.3333C14.1751 10.202 14.3744 10.1065 14.5864 10.0521C14.7984 9.99782 15.0191 9.9858 15.2358 10.0168C15.4524 10.0477 15.6609 10.121 15.8492 10.2325C16.0375 10.3441 16.2021 10.4916 16.3334 10.6667C16.4647 10.8418 16.5603 11.041 16.6146 11.253C16.6689 11.4651 16.6809 11.6857 16.65 11.9024C16.619 12.119 16.5457 12.3275 16.4342 12.5158C16.3227 12.7042 16.1751 12.8687 16 13C13.3934 14.955 11.9 16.8567 11.045 18.5167C12.219 18.2145 13.4579 18.2855 14.5896 18.7199C15.7214 19.1543 16.6896 19.9305 17.3599 20.9406C18.0301 21.9507 18.369 23.1445 18.3295 24.3561C18.2899 25.5676 17.8739 26.7368 17.1393 27.701C16.4046 28.6653 15.3878 29.3767 14.2302 29.7364C13.0726 30.0961 11.8317 30.0863 10.6799 29.7082C9.52815 29.3301 8.52278 28.6026 7.80356 27.6268C7.08433 26.651 6.68697 25.4754 6.66671 24.2633C6.45315 22.2165 6.74383 20.1486 7.51338 18.24C8.50838 15.73 10.4534 12.9933 14 10.3333ZM29 10.3333C29.1751 10.202 29.3744 10.1065 29.5864 10.0521C29.7984 9.99782 30.0191 9.9858 30.2358 10.0168C30.4524 10.0477 30.6609 10.121 30.8492 10.2325C31.0375 10.3441 31.2021 10.4916 31.3334 10.6667C31.4647 10.8418 31.5603 11.041 31.6146 11.253C31.6689 11.4651 31.6809 11.6857 31.65 11.9024C31.619 12.119 31.5457 12.3275 31.4342 12.5158C31.3227 12.7042 31.1751 12.8687 31 13C28.3934 14.955 26.9 16.8567 26.045 18.5167C27.219 18.2145 28.4579 18.2855 29.5896 18.7199C30.7214 19.1543 31.6896 19.9305 32.3599 20.9406C33.0301 21.9507 33.369 23.1445 33.3295 24.3561C33.2899 25.5676 32.8739 26.7368 32.1393 27.701C31.4046 28.6653 30.3878 29.3767 29.2302 29.7364C28.0726 30.0961 26.8317 30.0863 25.6799 29.7082C24.5281 29.3301 23.5228 28.6026 22.8036 27.6268C22.0843 26.651 21.687 25.4754 21.6667 24.2633C21.4532 22.2165 21.7438 20.1486 22.5134 18.24C23.51 15.73 25.4534 12.9933 29 10.3333Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
};
