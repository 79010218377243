import { Box } from "@mui/material";
import { testiomanalCMM } from "../../data/testimonals";
import {
  TestimonalDescription,
  TestimonalDescriptionBy,
  TestimonalLogo,
  TestimonalTitle,
  TestimonialContainer,
  TestimonialContentContainer,
  TestimonialQuoteContainer,
  TestimonialSection,
} from "../../styles/testimonals";

export default function Testimonals() {
  return (
    <TestimonialSection>
      <TestimonialContainer className="Testimonial-Container">
        <TestimonialContentContainer>
          <TestimonialQuoteContainer>
            <TestimonalTitle>{`Customer Testimonials`}</TestimonalTitle>
            <TestimonalDescription>
              {testiomanalCMM.desciption}
            </TestimonalDescription>
            <TestimonalDescriptionBy>
              {testiomanalCMM.desciptionBy}
            </TestimonalDescriptionBy>
          </TestimonialQuoteContainer>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { sm: "center", xs: "flex-start" },
              padding: "0px",
              gap: "16px",
              flex: "none",
              order: 2,
              alignSelf: "stretch",
              flexGrow: 0,
            }}
          >
            <TestimonalLogo src={testiomanalCMM.logo} />
          </Box>
        </TestimonialContentContainer>
      </TestimonialContainer>
    </TestimonialSection>
  );
}
