import { Stack, Box, useMediaQuery, useTheme } from "@mui/material";
import Banner from "../../components/banner";
import FeaturesHome from "../../components/feature/featureHome";
import OurStoryHomePage from "../../components/ourStory/ourStoryHomePage";
import Profile from "../../components/profile/Profile";
import Testimonals from "../../components/testimonals";
import Workings from "../../components/workings";
import Features from "../features";
import OurMisson from "../../components/misson";
import RequestDemo from "../../components/requestDemo";
import Solutions from "../../components/solutions";
import Benefits from "../../components/benefits";

import HowItWorks from "../../components/howItWorks";
import UserTestimonial from "../../components/userTestimonial";

export default function Home() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Banner />
      <OurMisson />
      <Features />
      <Solutions />
      <Benefits />
      <RequestDemo />
      <HowItWorks />
      <UserTestimonial />
      <OurStoryHomePage />
      <Testimonals />
      <RequestDemo />

      {/* <FeaturesHome /> 
      {/* <Workings /> */}
    </>
  );
}
