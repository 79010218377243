import {
  ProfileContainer,
  ProfileImage,
  ProfileImageContainer,
} from "../../styles/profile";

export default function Profile() {
  return (
    <ProfileContainer className="Profile-Container">
      <ProfileImageContainer>
        <ProfileImage src="/images/features/homepage.png"></ProfileImage>
      </ProfileImageContainer>
    </ProfileContainer>
  );
}
