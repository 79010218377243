import { useState } from "react";
import { useUpdateUserInfo } from "./useUpdateUserInfo";
import { ContactInfo } from "../components/contactUsPopup";

export default function useUpdateContactInfo() {
  const { userDataAdd } = useUpdateUserInfo();

  const [contactUsApiUpdates, setContactUsApiUpdates] = useState({
    data: {},
    error: {},
    loading: false,
  });

  const addContactDetails = async (userpayload: ContactInfo) => {
    setContactUsApiUpdates((prev) => ({ ...prev, loading: true }));

    let payload: any;
    payload = {
      email: userpayload.email,
      message: userpayload.query ? userpayload.query : null,
    };

    var payloadWithoutNull = Object.keys(payload)
      .filter((k) => payload[k] != null)
      .reduce((a, k) => ({ ...a, [k]: payload[k] }), {});

    userDataAdd({
      ...payloadWithoutNull,
    })
      .then((res) => {
        setContactUsApiUpdates((prev) => ({
          ...prev,
          loading: false,
          data: res,
        }));
      })
      .catch((error) => {
        setContactUsApiUpdates((prev) => ({
          ...prev,
          loading: false,
          error: error,
        }));
      });
  };
  return {
    addContactDetails,
    contactUsApiUpdates,
  };
}
