import React, { useState } from "react";
import {
  HowItWorkSlide,
  HowItWorksChip,
  HowItWorksContainer,
  HowItWorksContentContainer,
  HowItWorksSlides,
  HowItWorksTitle,
  SlideBody,
  SlideBodySubtitle,
  SlideBodyTitle,
  SlideCount,
} from "../../styles/howItWorks";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Colors } from "../../styles/theme";
import { useSwipeable } from "react-swipeable";

type ISlide = {
  id: number;
  idBgColor: string;
  title: string;
  titleColor: string;
  subtitle: string;
  subtitleColor: string;
};

const slides: ISlide[] = [
  {
    id: 1,
    idBgColor: "#7F56D9",
    title: "Connect To PM/EHR Systems",
    titleColor: "#7F56D9",
    subtitle:
      "Create an account within your systems for BrightVista to access the resources it needs.",
    subtitleColor: "#475467",
  },
  {
    id: 2,
    idBgColor: "#1570EF",
    title: "Import PM/EHR Data",
    titleColor: "#1570EF",
    subtitle:
      "Configure synchronization of your providers, locations, departments, specialties, and appointment types.",
    subtitleColor: "#475467",
  },
  {
    id: 3,
    idBgColor: "#444CE7",
    title: "Configure Appointments",
    titleColor: "#444CE7",
    subtitle:
      "Configure services, assign providers, set visit durations, as well as designate specialty, service, provider, and visit duration rules to automate your practice protocols and guidelines.",
    subtitleColor: "#475467",
  },
  {
    id: 4,
    idBgColor: "#0086C9",
    title: "Manage Patient Interface",
    titleColor: "#0086C9",
    subtitle:
      "Brand the patient experience, embed it in your website or leave it stand-alone. , Fine-tune BrightVista’s natural language understanding, and configure patient instructions.",
    subtitleColor: "#475467",
  },
  {
    id: 5,
    idBgColor: "#079455",
    title: "Monitor & Track Conversions",
    titleColor: "#079455",
    subtitle:
      "Track user conversion of sign up, sign in, appointment searches, provider and facility selection, as well as booking, cancelling and rescheduling appointments.",
    subtitleColor: "#475467",
  },
];

const HowItWorks = () => {
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = useState(1);

  const updateIndex = (newIndex: number, totalCount: number) => {
    if (newIndex < 1) {
      newIndex = totalCount;
    } else if (newIndex >= totalCount + 1) {
      newIndex = 1;
    }
    setPage(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(page + 1, slides.length),
    onSwipedRight: () => updateIndex(page - 1, slides.length),
  });

  return (
    <HowItWorksContainer id="howItWorks">
      <HowItWorksContentContainer>
        <HowItWorksChip label="How it works" />
        <HowItWorksTitle>
          Your Patients Will Be Booking Online In 4-20 Weeks
        </HowItWorksTitle>
        <HowItWorksSlides>
          {matchesMobile
            ? slides?.map((slide: ISlide, index: number) => (
                <>
                  {slide?.id === page ? (
                    <HowItWorkSlide {...handlers}>
                      <SlideCount sx={{ backgroundColor: slide?.idBgColor }}>
                        {slide?.id}
                      </SlideCount>
                      <SlideBody>
                        <SlideBodyTitle sx={{ color: slide?.titleColor }}>
                          {slide?.title}
                        </SlideBodyTitle>
                        <SlideBodySubtitle sx={{ color: slide?.subtitleColor }}>
                          {slide?.subtitle}
                        </SlideBodySubtitle>
                      </SlideBody>
                    </HowItWorkSlide>
                  ) : null}
                </>
              ))
            : slides?.map((slide: ISlide) => (
                <HowItWorkSlide>
                  <SlideCount sx={{ backgroundColor: slide?.idBgColor }}>
                    {slide?.id}
                  </SlideCount>
                  <SlideBody>
                    <SlideBodyTitle sx={{ color: slide?.titleColor }}>
                      {slide?.title}
                    </SlideBodyTitle>
                    <SlideBodySubtitle sx={{ color: slide?.subtitleColor }}>
                      {slide?.subtitle}
                    </SlideBodySubtitle>
                  </SlideBody>
                </HowItWorkSlide>
              ))}
        </HowItWorksSlides>
        {matchesMobile ? (
          <Box
            sx={{
              marginTop: "2rem",
              display: "flex",
              gap: "1rem",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {slides?.map((slide: ISlide, index: number) => (
              <React.Fragment key={slide?.id + index}>
                {slide?.id === page ? (
                  <Box
                    sx={{
                      width: "0.5rem",
                      height: "0.25rem",
                      borderRadius: "0.25rem",
                      backgroundColor: slide?.titleColor,
                      transition: "linear 0.1s",
                    }}
                    className="slideColor"
                  ></Box>
                ) : (
                  <Box
                    sx={{
                      width: "0.25rem",
                      height: "0.25rem",
                      borderRadius: "0.25rem",
                      backgroundColor: Colors.primary,
                      opacity: "0.32",
                    }}
                    onClick={() => setPage(slide?.id)}
                  ></Box>
                )}
              </React.Fragment>
            ))}
          </Box>
        ) : null}
      </HowItWorksContentContainer>
    </HowItWorksContainer>
  );
};

export default HowItWorks;
