import React from "react";
import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

interface IProps {
  open: boolean;
  onClose: () => void;
  width: string;
  height: string;
  children: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Popup = (props: IProps) => {
  const { open, onClose, width, height, children } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        zIndex: "1300",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        "& .MuiPaper-root": {
          width: width,
          maxWidth: "100%",
          margin: "0rem",
          height: height,
          maxHeight: "100%",
          boxSizing: "border-box",
        },
        "& .MuiDialog-container": {
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        },
      }}
      TransitionComponent={Transition}
    >
      {children}
    </Dialog>
  );
};

export default Popup;
