import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { team } from "../../data/ourStory";
import {
  CreativeTeamPagesContainer,
  CreativeTeamPagesIndex,
} from "../../styles/ourStory";
import OurStoryCard from "./ourStoryCard";
import { Box, useTheme } from "@mui/material";

export function CreativeTeamMobile() {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const updateIndex = (newIndex: number, totalCount: number) => {
    if (newIndex < 0) {
      newIndex = totalCount - 1;
    } else if (newIndex >= totalCount) {
      newIndex = 0;
    }
    setSelectedIndex(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(selectedIndex + 1, team.length),
    onSwipedRight: () => updateIndex(selectedIndex - 1, team.length),
  });

  return (
    <CreativeTeamPagesContainer className="CreativeTeam-Pages-Container">
      <Box>
        {team.map((employee, index) => (
          <CreativeTeamPagesIndex
            key={employee.id}
            theme={theme}
            selected={selectedIndex === index}
            variant="text"
            onClick={() => setSelectedIndex(index)}
          >
            {("0" + (index + 1)).slice(-2)}
          </CreativeTeamPagesIndex>
        ))}
      </Box>
      <Box
        sx={{
          width: { md: "62.2%", xs: "100%" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0px",
          flex: "none",
          order: 1,
          flexGrow: 0,
          gap: "40px",
        }}
        {...handlers}
      >
        <OurStoryCard
          key={team[selectedIndex].id}
          employee={team[selectedIndex]}
          width="100%"
          index={selectedIndex}
        />
      </Box>
    </CreativeTeamPagesContainer>
  );
}
