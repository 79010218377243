import { SvgIcon, SvgIconProps } from "@mui/material";
export const BubbleChat = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_1663_79919)">
        <rect x="2" y="1" width="48" height="48" fill="white" />
        <path
          d="M36 24.5667C36 29.8499 31.5222 34.1334 26 34.1334C25.3507 34.1343 24.7032 34.0742 24.0654 33.9545C23.6063 33.8682 23.3768 33.8251 23.2165 33.8496C23.0563 33.8741 22.8292 33.9948 22.375 34.2364C21.0901 34.9197 19.592 35.161 18.1511 34.893C18.6987 34.2194 19.0727 33.4112 19.2378 32.5448C19.3378 32.0148 19.09 31.5 18.7189 31.1231C17.0333 29.4115 16 27.1051 16 24.5667C16 19.2836 20.4778 15 26 15C31.5222 15 36 19.2836 36 24.5667Z"
          stroke="#2A353D"
          strokeWidth="1.5"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M25.9955 25H26.0045M29.991 25H30M22 25H22.009"
          stroke="#2A353D"
          strokeWidth="2"
          stroke-linecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <rect
          x="2.5"
          y="1.5"
          width="47"
          height="47"
          stroke="#EAECF0"
          fill="none"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1663_79919"
          x="0"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1663_79919"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1663_79919"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  );
};
