import { Block } from "@mui/icons-material";
import { Box, styled } from "@mui/material";

export const ProfileContainer = styled(Box)(({}) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  zIndex: "2",
  // border: "2px solid black"
}));
export const ProfileImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",

  //For 1240 and lower
  [theme.breakpoints.down("lg")]: {
    // padding: "0rem 10%",
    width: "77.5%",
  },

  //For 960 and lower
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const ProfileImage = styled(`img`)(({ theme }) => ({
  // width: "49.16%",
  display: "block",
  width: "100%",
  borderRadius: "28px",
}));
