import { Box, useMediaQuery, useTheme } from "@mui/material";
import useLiveDemo from "../../hooks/useLiveDemo";
import {
  BannerButtonConatiner,
  BannerButtonPrimary,
  BannerContainer,
  BannerContent,
  BannerContentContainer,
  BannerContentText,
  BannerTitle,
  BannerHeighlight,
  BannerHeaderContent,
  BannerHeading,
  BannerTextContent,
} from "../../styles/banner";
import Profile from "../profile/Profile";
import RequestDemoPopup from "../requestDemoPopup";

export default function Banner() {
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    liveDemoPopupStatus,
    openPopup,
    handleSendContactInfo,
    handleOpenPopup,
  } = useLiveDemo();
  return (
    <BannerContainer className="Banner-Container" id={"what-we-do-section"}>
      <BannerContentContainer>
        <BannerContent>
          <BannerHeaderContent>
            <BannerHeading>
              <Box
                sx={{
                  alignItems: "center",
                  textAlign: { md: "center", sm: "unset" },
                }}
              >
                <BannerTitle>Unlock the Power of </BannerTitle>{" "}
                <BannerHeighlight>Seamless Patient Access</BannerHeighlight>
                <BannerTitle> with BrightVista Gateway</BannerTitle>
              </Box>
              <BannerTextContent>
                <BannerContentText>
                  {`Imagine a revolutionary system where patients can effortlessly navigate your entire enterprise
              through a single gateway. Say goodbye to the days of dealing with multiple portals for various
              practices and systems within your organization. Welcome to BrightVista, where we seamlessly
              interoperate with diverse electronic health records and practice management systems.`}
                </BannerContentText>
              </BannerTextContent>
            </BannerHeading>
            <BannerButtonConatiner>
              <BannerButtonPrimary
                variant="contained"
                onClick={() => handleOpenPopup(true)}
              >
                Request Live Demo
              </BannerButtonPrimary>
            </BannerButtonConatiner>
          </BannerHeaderContent>
          {!matchesMobile && <Profile />}
        </BannerContent>
      </BannerContentContainer>
      <RequestDemoPopup
        status={liveDemoPopupStatus}
        openPopup={openPopup}
        handleOpenPopup={handleOpenPopup}
        handleSendContactInfo={handleSendContactInfo}
      />
    </BannerContainer>
  );
}
