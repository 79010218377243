import React, { useEffect, useState } from "react";
import { ContactInfo } from "../components/contactUsPopup";
import useUpdateContactInfo from "./useUpdateContactInfo";

const useContact = () => {
  const [showContactPopup, setShowContactPopup] = useState<boolean>(false);
  const [contactUsPopupStatus, setContactUsPopupStatus] = useState<
    "request" | "success" | "fail"
  >("request");
  const { addContactDetails, contactUsApiUpdates } = useUpdateContactInfo();

  const handleContactPopup = (bool: boolean) => {
    setShowContactPopup(bool);
    if (!bool) {
      setContactUsPopupStatus("request");
    }
  };

  const handleSendContactInfo = (contactData: ContactInfo) => {
    if (contactData.email) {
      addContactDetails(contactData);
    }
  };

  useEffect(() => {
    if (
      contactUsApiUpdates.loading === false &&
      contactUsApiUpdates.data &&
      Object.keys(contactUsApiUpdates.data).length
    ) {
      setContactUsPopupStatus("success");
    } else if (
      contactUsApiUpdates.loading === false &&
      contactUsApiUpdates.error &&
      Object.keys(contactUsApiUpdates.error).length
    ) {
      setContactUsPopupStatus("fail");
    }
  }, [contactUsApiUpdates]);

  return {
    contactUsPopupStatus,
    showContactPopup,
    handleContactPopup,
    handleSendContactInfo,
  };
};

export default useContact;
