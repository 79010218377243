import { Typography, useMediaQuery, useTheme } from "@mui/material";
import RequestDemoPopup from "../requestDemoPopup";
import { Colors } from "../../styles/theme";
import {
  RequestDemoContainer,
  RequestDemoContent,
  RequestDemoContentContainer,
  RequestLiveDemoButton,
} from "../../styles/requestDemo";
import { BannerButtonPrimary } from "../../styles/banner";
import useLiveDemo from "../../hooks/useLiveDemo";

export default function RequestDemo() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const {
    liveDemoPopupStatus,
    openPopup,
    handleSendContactInfo,
    handleOpenPopup,
  } = useLiveDemo();
  return (
    <RequestDemoContainer className="RequestDemo-Container">
      <RequestDemoContentContainer>
        <RequestDemoContent>
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: { sm: "2.25rem", xs: "2rem" },
              lineHeight: "2.375rem",
              color: Colors.white,
              width: { sm: "auto", xs: "18rem" },
            }}
          >
            {`Request Free Demo`}
          </Typography>
          <RequestLiveDemoButton
            variant="contained"
            onClick={() => handleOpenPopup(true)}
          >
            Request Live Demo
          </RequestLiveDemoButton>
        </RequestDemoContent>
      </RequestDemoContentContainer>
      <RequestDemoPopup
        status={liveDemoPopupStatus}
        openPopup={openPopup}
        handleOpenPopup={handleOpenPopup}
        handleSendContactInfo={handleSendContactInfo}
      />
    </RequestDemoContainer>
  );
}
