import { Box, styled, Typography } from "@mui/material";
import { Colors } from "../theme";

export const TestimonialSection = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  padding: "4.5rem 0rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "#FFF",

  //For 1439 and lower
  [theme.breakpoints.down("lg")]: {
    padding: "3.375rem 0rem",
  },
  //For 1239 and lower
  [theme.breakpoints.down("md")]: {
    padding: "3rem 0rem",
  },
  //For 960 and lower
  [theme.breakpoints.down("sm")]: {
    padding: "3rem 0rem",
  },
}));

export const TestimonialContainer = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  flexDirection: "column",
  gap: "2rem",
  width: "75.05%",
  height: "100%",
  padding: "0rem 2rem",
  display: "flex",
  alignItems: "flex-start",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    width: "87.09%",
  },
  //For 960 and lower
  [theme.breakpoints.down("md")]: {
    width: "91.66%",
  },
  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const TestimonialContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "4rem",
  gap: "2rem",
  alignSelf: "stretch",
  background: "#fff",
  boxShadow: "-40px 40px 80px 0px rgba(132, 132, 132, 0.16)",

  //For 960 and lower
  [theme.breakpoints.down("sm")]: {
    padding: "2.5rem 1.25rem",
  },
}));

export const TestimonialQuoteContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0px",
  gap: "0.75rem",
  alignSelf: "stretch",
}));

export const TestimonalTitle = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "1rem",
  lineHeight: "1.25rem",
  width: "100%",
  textAlign: "center",
  color: theme.palette.primary.main,
  //For 960 and lower
  [theme.breakpoints.down("sm")]: {
    textAlign: "unset",
  },
}));

export const TestimonalDescription = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "1.75rem",
  lineHeight: "2.1875rem",
  textAlign: "center",
  width: "100%",
  color: theme.palette.primary.main,

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.5rem",
    lineHeight: "1.875rem",
  },

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    textAlign: "unset",
  },
}));

export const TestimonalDescriptionBy = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "1.25rem",
  lineHeight: "1.5625rem",
  textAlign: "center",
  width: "100%",
  color: theme.palette.primary.main,

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.25rem",
  },

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    textAlign: "unset",
  },
}));

export const TestimonalLogo = styled(`img`)(({ theme }) => ({
  flex: "none",
  order: 0,
  flexGrow: 0,
}));
