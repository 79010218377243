import { createTheme } from "@mui/material";
export const Colors = {
  primary: "#1e425f",
  secondary: "#378dbd",
  white: "#fff",
  black: "#000",
  body_bg: "#F4FBFF",
  text_secondary: "#4A5468",
  text_Neutral: "#A0ABC0",
  main_800: "#378DBD",
  highlight: "#E1F5FF",
  neutral_800: "#2D3648",
  neutral_700: "#0B141F",
  neutral_900: "#1A202C",
  neutral_100: "#F7F9FC",
  neutral_200: "#EDF0F7",
  //  #2D3648
  // silent text #
  // Nav #717D96
  //#378DBD
  gray: "#475467",
  gray_200: "#EAECF0",
  gray_600: "#475467",
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 961,
      lg: 1241,
      xl: 1440,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
    },
  },
  typography: {
    fontFamily: ["Spectral", "sans-serif"].join(","),
    fontWeightMedium: 700,
  },
});

export default theme;
