import { Client } from "../clients/Client";
import { IClientOperationResponse } from "../clients/types";
import { URL_USER_INFO } from "../data/url";

class UserInfo {
  public Client: Client;

  constructor() {
    this.Client = new Client();
  }

  public userDataAdd = async (payload: any) => {
    let request: IClientOperationResponse;
    request = this.Client.userInfoPost(payload, URL_USER_INFO);
    return await request.axiosPromise;
  };
}

const profile = new UserInfo();

export const useUpdateUserInfo = () => {
  return {
    userDataAdd: profile.userDataAdd,
  };
};
