import { Box, BoxProps, Typography, styled } from "@mui/material";
import { Colors } from "../theme";

interface SolutionsBoxProps extends BoxProps {
  highlight: string;
}

interface SolutionBoxIconProps extends BoxProps {
  down: string;
}

export const SolutionsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.neutral_100,
  width: "100%",
  padding: "4.5rem 0rem",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  [theme.breakpoints.down("lg")]: {
    padding: "3.375rem 0rem",
  },
  [theme.breakpoints.down("md")]: {
    padding: "3rem 0rem",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "3rem 0rem",
  },
}));

export const SolutionsBody = styled(Box)(({ theme }) => ({
  width: "75.03%",
  padding: "0rem 2rem",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",

  gap: "3rem",
  [theme.breakpoints.down("lg")]: {
    width: "87.09%",
  },
  //For 960 and lower
  [theme.breakpoints.down("md")]: {
    width: "91.66%",
    justifyContent: "center",
    alignItems: "center",
  },

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    padding: "0rem 1rem",
    width: "auto",
    alignSelf: "stretch",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0rem",
  },
}));

export const SolutionsBodyHeader = styled(Box)(({ theme }) => ({
  width: "100%",
}));

export const HeadingName = styled(Typography)(({ theme }) => ({
  width: "max-content",
  maxWidth: "100%",
  padding: "0.25rem 1rem",
  marginBottom: "1.75rem",
  borderRadius: "3.125rem",
  backgroundColor: "rgba(30, 66, 95, 0.10)",
  fontSize: "1rem",
  fontWeight: "700",
  color: Colors.primary,
  boxSizing: "border-box",
}));

export const HeadingTitle = styled(Typography)(({ theme }) => ({
  color: Colors.primary,
  fontSize: "2.625rem",
  fontWeight: "700",
  lineHeight: "125%",
  marginBottom: "0.5rem",
}));

export const HeadingSubtitle = styled(Typography)(({ theme }) => ({
  color: "#101828",
  fontSize: "1.25rem",
  fontWeight: "500",
  lineHeight: "150%",
}));

export const SolutionsBodySection = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  gap: "2.5rem",
  flexWrap: "wrap",
  boxSizing: "border-box",

  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",

  //For 960 and lower
  [theme.breakpoints.down("md")]: {
    width: "98%",
  },

  [theme.breakpoints.down("sm")]: {
    flexWrap: "no-wrap",
    width: "100%",
  },
}));

export const SolutionsBox = styled(Box)<SolutionsBoxProps>(
  ({ theme, highlight }) => ({
    width: "29%",
    padding: "2.5rem 1.5rem",
    backgroundColor: highlight === "true" ? "#fff" : Colors.neutral_100,
    boxShadow:
      highlight === "true"
        ? "-40px 40px 80px 0px rgba(145, 158, 171, 0.16)"
        : "none",
    display: "flex",
    flexDirection: "column",
    gap: "4rem",
    boxSizing: "border-box",
    borderRadius: "16px",

    [theme.breakpoints.down("lg")]: {
      width: "32.6%",
    },

    //For 960 and lower
    [theme.breakpoints.down("md")]: {
      width: "41.3%",
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "2.5rem 3.75rem",
    },
  })
);

export const SolutionBoxIcon = styled(Box)<SolutionBoxIconProps>(
  ({ theme, down }) => ({
    height: down === "true" ? "6rem" : "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
  })
);

export const SolutionBoxBody = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "1rem",
  alignSelf: "stretch",
}));

export const SolutionBoxTitle = styled(Typography)(({ theme }) => ({
  color: Colors.primary,
  textAlign: "center",
  fontSize: "1.5rem",
  fontWeight: "700",
  lineHeight: "1.875rem",
  alignSelf: "stretch",
}));

export const SolutionBoxDescription = styled(Typography)(({ theme }) => ({
  color: Colors.gray,
  textAlign: "center",
  fontSize: "1.25rem",
  fontWeight: "500",
  lineHeight: "1.5rem",
  alignSelf: "stretch",
  fontStyle: "normal",
}));
