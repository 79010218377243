import {
  Box,
  Button,
  InputBase,
  InputLabel,
  styled,
  TextField,
  Typography,
} from "@mui/material";

import { Colors } from "../theme";

export const BannerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "84.45%",
  justifyContent: "center",
  alignItems: "center",
  padding: "4.5rem 0rem",
  marginLeft: "7.79%",
  marginRight: "7.79%",
  gap: "4rem",

  [theme.breakpoints.down("lg")]: {
    width: "100%",
    margin: 0,
    padding: "3.375rem 0rem",
  },

  [theme.breakpoints.down("md")]: {
    padding: "3rem 0rem",
  },

  // //For 499 and lower
  // [theme.breakpoints.down("sm")]: {},
}));

export const BannerContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "88.88%",
  padding: "0rem 2rem 0.25rem 2rem",
  boxSizing: "border-box",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    padding: 0,
    alignSelf: "stretch",
  },

  //For 960 and lower
  [theme.breakpoints.down("md")]: {
    padding: "0rem 2.5rem 2.0625rem 2.5rem",
  },

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    padding: "0rem 1rem",
    gap: "0.625rem",
  },
}));

export const BannerContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "3.75rem",
  flex: "1 0 0",
  boxSizing: "border-box",
}));

export const BannerHeaderContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "3rem",
  alignItems: "center",

  [theme.breakpoints.down("lg")]: {
    width: "87.09%",
  },
  [theme.breakpoints.down("md")]: {
    alignItems: "flex-start",
    width: "100%",
    alignself: "stretch",
  },

  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    flex: "1 0 0",
  },
}));

export const BannerHeading = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "90.7%",
  flexDirection: "column",
  alignItems: "center",
  gap: "1.5rem",

  [theme.breakpoints.down("lg")]: {
    alignItems: "center",
    width: "87.09%",
  },
  [theme.breakpoints.down("md")]: {
    alignItems: "flex-start",
    width: "100%",
    alignself: "stretch",
  },

  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    flex: "1 0 0",
  },
}));

export const BannerTitle = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: "3.5rem",
  lineHeight: "125%",
  width: "100%",

  color: Colors.primary,
  flex: "none",
  order: 0,
  flexGrow: 0,
  display: "inline",

  //For 1239 and lower
  [theme.breakpoints.down("md")]: {
    fontSize: "2.75rem",
  },
  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
  },
}));

export const BannerHeighlight = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: "3.5rem",
  lineHeight: "125%",
  width: "100%",
  display: "inline",
  color: Colors.main_800,
  flex: "none",
  order: 0,
  flexGrow: 0,

  //For 1239 and lower
  [theme.breakpoints.down("md")]: {
    fontSize: "2.75rem",
  },
  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
  },
}));

export const BannerTextContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "32px",
  width: "80%",
  //For 1239 and lower
  [theme.breakpoints.down("md")]: {},
  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  flex: "none",
  order: 1,
  flexGrow: 0,
  boxSizing: "border-box",
}));

export const BannerContentText = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "1.25rem",
  lineHeight: "135%",
  /* or 78px */
  textAlign: "center",

  //For 1439 and lower
  [theme.breakpoints.down("lg")]: {
    // height: "55%",
  },

  [theme.breakpoints.down("md")]: {
    width: "85%",
    textAlign: "unset",
  },

  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    textAlign: "unset",
  },

  color: Colors.text_secondary,
  flex: "none",
  order: 0,
  flexGrow: 0,
}));

export const BannerButtonConatiner = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: "0px",
  gap: "24px",
  width: "100%",
  // height: "35%",
  flex: "none",
  order: 1,
  flexGrow: 0,
  justifyContent: "center",

  //For 1239 and lower
  [theme.breakpoints.down("md")]: {
    // height: "30%",
    justifyContent: "start",
  },

  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "8px",
    alignSelf: "stretch",
  },
}));

export const BannerButtonPrimary = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "3rem",
  padding: "0.75rem 1.25rem",
  gap: "0.5rem",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  borderRadius: "0px",
  textTransform: "none",
  fontSize: "1.125rem",
  fontWeight: "600",
  lineHeight: "1.5rem",

  //For 1239 and lower
  [theme.breakpoints.down("md")]: {},

  //For 904 and lower
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    // height: "45%",
    alignSelf: "stretch",
    flexGrow: 0,
  },
}));

export const PopupContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  padding: "1.5rem",
}));

export const LiveDemoHeadingPopup = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "3rem",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "1rem",
}));

export const LiveDemoTextHeading = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: "1.25rem",
}));

export const LiveDemoPopupTitle = styled(Typography)(({ theme }) => ({
  width: "100%",
  color: Colors.primary,
  fontSize: "1.5rem",
  fontWeight: "700",
  lineHeight: "125%",
  marginBottom: "0.5rem",
}));

export const LiveDemoPopupSubtitle = styled(Typography)(({ theme }) => ({
  width: "100%",
  color: Colors.neutral_700,
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "150%",
}));

export const LiveDemoForm = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: "2rem",
}));

export const UserInputFieldLabel = styled(InputLabel)(({ theme }) => ({
  width: "100%",
  transform: "none",
  fontSize: "1rem",
  fontWeight: 700,
  lineHeight: "150%",
  color: Colors.neutral_700,
}));

export const UserInputField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    "&:focus-within": {
      backgroundColor: "#FFF",
      color: "#2D3648",
      borderColor: "#CBD2E0",

      "& path": {
        stroke: "#2D3648",
      },
    },

    border: "1px solid #CBD2E0",
    marginTop: theme.spacing(3),
    borderColor: "#CBD2E0",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-error": {
      color: "#E93E3A",
      borderColor: "#E93E3A",
      "& path": {
        stroke: "#E93E3A",
      },
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "1rem",
    padding: "10px 12px !important",
    borderColor: "#CBD2E0",
  },
}));

export const UserInputTextField = styled(TextField)(({ theme }) => ({
  borderRadius: "0rem",
  "label + &": {
    "&:focus-within": {
      backgroundColor: "#FFF",
      color: "#2D3648",
      borderColor: "#CBD2E0",
      borderRadius: "0rem",

      "& path": {
        stroke: "#2D3648",
        borderRadius: "0rem",
      },
    },

    border: "1px solid #CBD2E0",
    marginTop: theme.spacing(3),
    borderColor: "#CBD2E0",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-error": {
      color: "#E93E3A",
      borderColor: "#E93E3A",
      "& path": {
        stroke: "#E93E3A",
      },
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "1rem",
    padding: "10px 12px !important",
    borderColor: "#CBD2E0",
    borderRadius: "0rem",
  },

  "& .MuiInputBase-multiline": {
    borderRadius: "0rem",
  },
}));

export const LiveDemoButtons = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: "0.75rem",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
  },
}));

export const BVButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  width: "50%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "12px 16px",
  gap: "8px",
  fontSize: "16px",
  lineHeight: "125%",
  fontWeight: 600,
  textAlign: "center",
  boxShadow: "none",
  color: "#fff",

  "&:hover": {
    background: theme.palette.primary.dark,
    color: "#fff",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: theme.palette.primary.light,
    color: "#fff",
  },
}));
