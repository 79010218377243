import React from "react";
import { Box, Rating, Typography } from "@mui/material";
import { QuoteLeft } from "../icons/QuoteLeft";

type IProps = {
  testimonial: string;
  rating: number;
};

const TestimonialCard = (props: IProps) => {
  const { testimonial, rating } = props;
  return (
    <Box
      sx={{
        width: "100%",
        padding: "1.5rem",
        borderRadius: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        backgroundColor: "rgba(255, 255, 255, 0.15)",
        backdropFilter: "blur(10px)",
        boxSizing: "border-box",
      }}
    >
      <Box>
        <QuoteLeft
          sx={{
            width: "2.5rem",
            height: "2.5rem",
            opacity: "0.48",
          }}
        />
      </Box>
      <Typography
        sx={{
          fontSize: "1.125rem",
          fontWeight: "400",
          fontStyle: "normal",
          lineHeight: "1.375rem",
          color: "#fff",
        }}
      >
        {testimonial}
      </Typography>
      <Box>
        <Rating name="rating" value={rating} readOnly />
      </Box>
    </Box>
  );
};

export default TestimonialCard;
