import { Colors } from "../../styles/theme";

export const OurStoryHomePageContent =
  "With years of experience in healthcare delivery and software development, Dr. Veit and Sehayek embarked on a journey to create a comprehensive platform that would improve the digital experience for patients and providers, while also enhancing operational efficiencies.";

export const team = [
  {
    id: 1,
    name: "Ilan Sehayek",
    designation: "Chief Executive Officer",
    description:
      "Ilan is a seasoned enterprise software professional with more than 30 years of experience in engineering, product management, and building technology companies from scratch. He has worked at prominent tech giants like Amazon and Microsoft, where he was involved in creating and managing user engagement services and building API and data integration companies that have become leaders in their respective markets. Ilan's passion for leveraging his expertise in system interoperability and user engagement has led him to the healthcare industry, where he is dedicated to improving the healthcare journey for both patients and providers.",
    image: "/images/ourStory/Founder_2.png",
    backgroundColor: Colors.body_bg,
  },
  {
    id: 2,
    name: "Stephen Batuello MD MBA",
    designation: "Chief Operating Officer",
    description:
      "Dr. Batuello is an accomplished surgeon and physician executive with extensive clinical experience in various healthcare settings, ranging from private surgical practice to tertiary care hospitals. He has held numerous leadership roles, including board chairman for a multi-specialty medical group, chief medical officer for a large inner-city hospital, and CEO of a life sciences company focused on genomics and precision medicine. Dr. Batuello's expertise extends beyond healthcare, having served as an advisor and partner to various technology companies and healthcare consulting firms.",
    image: "/images/ourStory/Img_3.png",
    backgroundColor: Colors.primary,
  },
  {
    id: 3,
    name: "Andrew Veit MD MBA",
    designation: "Chief Medical Officer",
    description:
      "Dr. Veit is an experienced healthcare professional with 25 years of clinical and administrative experience. He has been involved in hospital governance, strategic business development, mergers and acquisitions, medical device development, and private equity partnership. Dr. Veit is passionate about leveraging innovation to improve the healthcare journey for both patients and providers.",
    image: "/images/ourStory/Img_1.png",
    backgroundColor: Colors.highlight,
  },
  {
    id: 4,
    name: "Bryan Bolling",
    designation: "VP Sales & Business Development",
    description:
      "Bryan is a successful technology business executive and entrepreneur for 24 years. He founded and grew Bear Medical Partners and Bear Medical Supply.  For the past 13 years, his focus has been helping healthcare transform their businesses through massive cost savings and change management resulting in high returns. Bryan has led multiple sales teams and focused most of his efforts on taking pre-unicorn technology companies to the next level. Adopting a customer-centric sales approach, Bryan believes it is essential to build long-term relationships with customers by focusing on their company mandates and organizational goals while providing top-quality customer service to provide lasting impact to the patient, the provider, and the bottom-line.",
    image: "/images/ourStory/Founder_4.png",
    backgroundColor: Colors.secondary,
  },
];
