import { FormControl } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { AlertCircle } from "../icons/AlertCircle";
import { CheckCircle } from "../icons/CheckCircle";
import Popup from "../popup/Popup";
import {
  LiveDemoHeadingPopup,
  LiveDemoTextHeading,
  LiveDemoForm,
  LiveDemoButtons,
  PopupContainer,
  LiveDemoPopupTitle,
  LiveDemoPopupSubtitle,
  UserInputFieldLabel,
  UserInputField,
  BVButton,
  UserInputTextField,
} from "../../styles/banner";
import { Colors } from "../../styles/theme";
import { BubbleChat } from "../icons/BubbleChat";
import { useEffect, useState } from "react";

export interface ContactInfo {
  email: string;
  query?: string;
}

interface IProps {
  status: "request" | "success" | "fail";
  showContactPopup: boolean;
  handleContactPopup: (bool: boolean) => void;
  handleSendContactInfo: (contactData: ContactInfo) => void;
}

const ContactUsPopup = (props: IProps) => {
  const {
    status,
    showContactPopup,
    handleContactPopup,
    handleSendContactInfo,
  } = props;
  const innitalState = {
    email: "",
    query: "",
  };
  const [contactInfo, setContactInfo] = useState<ContactInfo>({
    ...innitalState,
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setContactInfo((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    // validateFields(event.target.name, event.target.value);
    // if (!validator.isEmail(value)) {
  };

  return (
    <Popup
      open={showContactPopup}
      onClose={() => {
        setContactInfo({ ...innitalState });
        handleContactPopup(false);
      }}
      width={"40rem"}
      height={"auto"}
    >
      <PopupContainer>
        {status === "request" ? (
          <>
            <LiveDemoHeadingPopup>
              <BubbleChat sx={{ height: "52px", width: "52px" }}></BubbleChat>

              <ClearRoundedIcon
                sx={{ color: "#667085", cursor: "pointer" }}
                onClick={() => {
                  setContactInfo({ ...innitalState });
                  handleContactPopup(false);
                }}
              />
            </LiveDemoHeadingPopup>
            <LiveDemoTextHeading>
              <LiveDemoPopupTitle>Contact Us</LiveDemoPopupTitle>
              <LiveDemoPopupSubtitle>
                Contact us and we get back to you ASAP!
              </LiveDemoPopupSubtitle>
            </LiveDemoTextHeading>
            <LiveDemoForm>
              <FormControl
                fullWidth
                sx={{ width: "100%", marginBottom: "1rem" }}
                variant="standard"
              >
                <UserInputFieldLabel htmlFor="email-input">
                  <div>{"Email address"}</div>
                </UserInputFieldLabel>
                <UserInputField
                  id="email-input"
                  name="email"
                  value={contactInfo.email}
                  onChange={handleInputChange}
                  placeholder="e.g. Johnsmith@gmail.com"
                  error={false}
                  required
                />
              </FormControl>
              <FormControl fullWidth sx={{ width: "100%" }} variant="standard">
                <UserInputFieldLabel htmlFor="queries">
                  <div>{"Any Queries"}</div>
                </UserInputFieldLabel>
                <UserInputTextField
                  id="query"
                  name="query"
                  multiline={true}
                  value={contactInfo.query}
                  onChange={handleInputChange}
                  placeholder="e.g. I joined Stripe’s Customer Success team to help them scale their checkout product. I focused mainly on onboarding new customers and resolving complaints."
                  error={false}
                />
              </FormControl>
            </LiveDemoForm>
            <LiveDemoButtons>
              <BVButton
                variant="outlined"
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "125%",
                  borderColor: "#CBD2E0",
                  borderRadius: "0px",
                  color: Colors.neutral_700,
                }}
                className="mt2"
                onClick={() => {
                  setContactInfo({ ...innitalState });
                  handleContactPopup(false);
                }}
              >
                Cancel
              </BVButton>
              <BVButton
                variant="contained"
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "125%",
                  borderRadius: "0px",
                  backgroundColor: Colors.primary,
                }}
                disabled={false}
                onClick={() => handleSendContactInfo(contactInfo)}
              >
                Send
              </BVButton>
            </LiveDemoButtons>
          </>
        ) : status === "success" ? (
          <>
            <LiveDemoHeadingPopup>
              <CheckCircle sx={{ height: "52px", width: "52px" }}></CheckCircle>
              <ClearRoundedIcon
                sx={{ color: "#667085", cursor: "pointer" }}
                onClick={() => handleContactPopup(false)}
              />
            </LiveDemoHeadingPopup>
            <LiveDemoTextHeading>
              <LiveDemoPopupTitle>
                Thank You For Contacting us.{" "}
              </LiveDemoPopupTitle>
              <LiveDemoPopupSubtitle>
                We will get back to you ASAP!
              </LiveDemoPopupSubtitle>
            </LiveDemoTextHeading>
            <LiveDemoButtons>
              <BVButton
                variant="contained"
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "125%",
                  borderRadius: "0px",
                  backgroundColor: Colors.primary,
                }}
                onClick={() => {
                  setContactInfo({ ...innitalState });
                  handleContactPopup(false);
                }}
              >
                Cancel
              </BVButton>
            </LiveDemoButtons>
          </>
        ) : (
          <>
            <LiveDemoHeadingPopup>
              <AlertCircle sx={{ height: "52px", width: "52px" }}></AlertCircle>
              <ClearRoundedIcon
                sx={{ color: "#667085", cursor: "pointer" }}
                onClick={() => handleContactPopup(false)}
              />
            </LiveDemoHeadingPopup>
            <LiveDemoTextHeading>
              <LiveDemoPopupTitle sx={{ color: "#E93E3A" }}>
                Sorry, Something Went Wrong
              </LiveDemoPopupTitle>
              <LiveDemoPopupSubtitle>
                Can you please try again
              </LiveDemoPopupSubtitle>
            </LiveDemoTextHeading>
            <LiveDemoButtons>
              <BVButton
                variant="outlined"
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "125%",
                  borderColor: "#CBD2E0",
                  borderRadius: "0px",
                  color: Colors.neutral_700,
                }}
                className="mt2"
                onClick={() => {
                  setContactInfo({ ...innitalState });
                  handleContactPopup(false);
                }}
              >
                Cancel
              </BVButton>
              <BVButton
                variant="contained"
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "125%",
                  borderRadius: "0px",
                  backgroundColor: Colors.primary,
                }}
                disabled={false}
                onClick={() => {}}
              >
                Retry
              </BVButton>
            </LiveDemoButtons>
          </>
        )}
      </PopupContainer>
    </Popup>
  );
};

export default ContactUsPopup;
