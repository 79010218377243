import React, { useState } from "react";
import { FormControl, useMediaQuery, useTheme } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import Popup from "../popup/Popup";
import {
  LiveDemoHeadingPopup,
  LiveDemoTextHeading,
  LiveDemoForm,
  LiveDemoButtons,
  PopupContainer,
  LiveDemoPopupTitle,
  LiveDemoPopupSubtitle,
  UserInputFieldLabel,
  UserInputField,
  BVButton,
} from "../../styles/banner";
import { Colors } from "../../styles/theme";
import { FeaturedIcon } from "../icons/FeaturedIcon";
import { AlertCircle } from "../icons/AlertCircle";
import { CheckCircle } from "../icons/CheckCircle";
import { ContactInfo } from "../contactUsPopup";

interface IProps {
  status: "request" | "success" | "fail";
  openPopup: boolean;
  handleOpenPopup: (bool: boolean) => void;
  handleSendContactInfo: (contactData: ContactInfo) => void;
}

const RequestDemoPopup = (props: IProps) => {
  const { status, openPopup, handleOpenPopup, handleSendContactInfo } = props;
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const innitalState = {
    email: "",
  };
  const [contactInfo, setContactInfo] = useState<ContactInfo>({
    ...innitalState,
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setContactInfo((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    // validateFields(event.target.name, event.target.value);
    // if (!validator.isEmail(value)) {
  };
  return (
    <Popup
      open={openPopup}
      onClose={() => {
        setContactInfo({ ...innitalState });
        handleOpenPopup(false);
      }}
      width={matchesMobile ? "21.44rem" : "35.31rem"}
      height={"auto"}
    >
      <PopupContainer>
        {status === "request" ? (
          <>
            <LiveDemoHeadingPopup>
              <FeaturedIcon
                sx={{ height: "52px", width: "52px" }}
              ></FeaturedIcon>
              <ClearRoundedIcon
                sx={{ color: "#667085", cursor: "pointer" }}
                onClick={() => {
                  setContactInfo({ ...innitalState });
                  handleOpenPopup(false);
                }}
              />
            </LiveDemoHeadingPopup>
            <LiveDemoTextHeading>
              <LiveDemoPopupTitle>Request Live Demo</LiveDemoPopupTitle>
              <LiveDemoPopupSubtitle>
                Please give us your email address, and we will be get back to
                you ASAP!
              </LiveDemoPopupSubtitle>
            </LiveDemoTextHeading>
            <LiveDemoForm>
              <FormControl fullWidth sx={{ width: "100%" }} variant="standard">
                <UserInputFieldLabel htmlFor="email-input">
                  <div>{"Email address"}</div>
                </UserInputFieldLabel>
                <UserInputField
                  id="email-input"
                  name="email"
                  value={contactInfo.email}
                  onChange={handleInputChange}
                  placeholder="e.g. Johnsmith@gmail.com"
                  error={false}
                />
              </FormControl>
            </LiveDemoForm>
            <LiveDemoButtons>
              <BVButton
                variant="outlined"
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "125%",
                  borderColor: "#CBD2E0",
                  borderRadius: "0px",
                  color: Colors.neutral_700,
                }}
                className="mt2"
                onClick={() => {
                  setContactInfo({ ...innitalState });
                  handleOpenPopup(false);
                }}
              >
                Cancel
              </BVButton>
              <BVButton
                variant="contained"
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "125%",
                  borderRadius: "0px",
                  backgroundColor: Colors.primary,
                }}
                disabled={false}
                onClick={() => handleSendContactInfo(contactInfo)}
              >
                Confirm
              </BVButton>
            </LiveDemoButtons>
          </>
        ) : status === "success" ? (
          <>
            <LiveDemoHeadingPopup>
              <CheckCircle sx={{ height: "52px", width: "52px" }}></CheckCircle>
              <ClearRoundedIcon
                sx={{ color: "#667085", cursor: "pointer" }}
                onClick={() => {
                  setContactInfo({ ...innitalState });
                  handleOpenPopup(false);
                }}
              />
            </LiveDemoHeadingPopup>
            <LiveDemoTextHeading>
              <LiveDemoPopupTitle>
                Thank You For Requesting a Live Demo{" "}
              </LiveDemoPopupTitle>
              <LiveDemoPopupSubtitle>
                We will get back to you ASAP!
              </LiveDemoPopupSubtitle>
            </LiveDemoTextHeading>
            <LiveDemoButtons>
              <BVButton
                variant="contained"
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "125%",
                  borderRadius: "0px",
                  backgroundColor: Colors.primary,
                }}
                onClick={() => {
                  setContactInfo({ ...innitalState });
                  handleOpenPopup(false);
                }}
              >
                Cancel
              </BVButton>
            </LiveDemoButtons>
          </>
        ) : (
          <>
            <LiveDemoHeadingPopup>
              <AlertCircle sx={{ height: "52px", width: "52px" }}></AlertCircle>
              <ClearRoundedIcon
                sx={{ color: "#667085", cursor: "pointer" }}
                onClick={() => {
                  setContactInfo({ ...innitalState });
                  handleOpenPopup(false);
                }}
              />
            </LiveDemoHeadingPopup>
            <LiveDemoTextHeading>
              <LiveDemoPopupTitle sx={{ color: "#E93E3A" }}>
                Sorry, Something Went Wrong
              </LiveDemoPopupTitle>
              <LiveDemoPopupSubtitle>
                Can you please try again
              </LiveDemoPopupSubtitle>
            </LiveDemoTextHeading>
            <LiveDemoButtons>
              <BVButton
                variant="outlined"
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "125%",
                  borderColor: "#CBD2E0",
                  borderRadius: "0px",
                  color: Colors.neutral_700,
                }}
                className="mt2"
                onClick={() => {
                  setContactInfo({ ...innitalState });
                  handleOpenPopup(false);
                }}
              >
                Cancel
              </BVButton>
              <BVButton
                variant="contained"
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "125%",
                  borderRadius: "0px",
                  backgroundColor: Colors.primary,
                }}
                disabled={false}
                onClick={() => {}}
              >
                Retry
              </BVButton>
            </LiveDemoButtons>
          </>
        )}
      </PopupContainer>
    </Popup>
  );
};

export default RequestDemoPopup;
