import { Container, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import "./App.css";
import AppBar from "./components/appBar";
import Footer from "./components/footer";
import { Router } from "./routes";
import theme, { Colors } from "./styles/theme";

function App() {
  useEffect(() => {
    document.title = "Brightvista";
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: "column",
          background: Colors.white,
          width: "100%",
          height: "100%",
          padding: "0px !important",
          position: "relative",
        }}
      >
        <AppBar />
        <Router />
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default App;
