import { Box, styled, Typography } from "@mui/material";

export const OurMissonContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "4.5rem 0rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "#F4FBFF",
  boxSizing: "border-box",
  gap: "4rem",
  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    padding: "3.375rem 0rem",
  },

  //For 960 and lower
  [theme.breakpoints.down("md")]: {
    padding: "3rem 0rem",
  },

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    padding: "3rem 1rem",
  },
}));

export const OurMissonContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "75.02%",
  padding: "0rem 2rem",
  boxSizing: "border-box",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "2rem",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    width: "96.1%",
    alignItems: "center",
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    width: "91.6%",
  },

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "0",
    alignSelf: "stretch",
  },
}));

export const OurMissonTextFrame = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  alignSelf: "stretch",
}));

export const OurMissonTitle = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "2rem",
  lineHeight: "135%",

  width: "100%",
  textAlign: "center",
  color: "#717D96",
  boxSizing: "border-box",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.625rem",
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    fontSize: "1.375rem",
  },

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    textAlign: "unset",
    fontSize: "1.5rem",
    alignSelf: "stretch",
  },
}));

export const OurMissonDescription = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "2rem",
  lineHeight: "135%",
  textAlign: "center",
  width: "85.03%",
  color: theme.palette.primary.main,
  boxSizing: "border-box",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.625rem",
    width: "90%",
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    fontSize: "1.375rem",
    width: "auto",
  },
  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    fontSize: "1.375rem",
    width: "auto",
  },

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    textAlign: "unset",
    fontSize: "1.5rem",
  },
}));
