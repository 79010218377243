import { Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import {
  CreativeTeamIndexTitle,
  CreativeTeamMemberDescription,
  CreativeTeamMemberDesignation,
  CreativeTeamMemberName,
  OurStoryTextContainer,
  OurStoryTextMemberNameContainer,
  OurStorysHomeImageNew,
  OurStorysImageContainer,
} from "../../styles/ourStory";

interface employee {
  id: number;
  name: string;
  designation: string;
  description: string;
  backgroundColor: string;
  image: string;
}

interface OurStoryCardProps {
  width: string;
  index: number;
  employee: employee;
}

export default function OurStoryCard({
  width,
  index,
  employee,
}: OurStoryCardProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Box
      className="OurStory-Card"
      sx={{
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: { md: "20px", xs: "0px" },
        justifyContent: "space-between",
      }}
    >
      {/* {matches ? (
        <CreativeTeamIndexTitle className="OurStory-Index">
          {("0" + (index + 1)).slice(-2)}
        </CreativeTeamIndexTitle>
      ) : null} */}

      <Box
        className="Content-Container"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0px",
          flex: "none",
          order: 0,
          flexGrow: 1,
          zIndex: 2,
          width: "82%",
        }}
      >
        <OurStoryTextContainer className="OurStory-Text-Container">
          <OurStoryTextMemberNameContainer>
            <CreativeTeamMemberName>{employee.name}</CreativeTeamMemberName>
            <CreativeTeamMemberDesignation>
              {employee.designation}
            </CreativeTeamMemberDesignation>
          </OurStoryTextMemberNameContainer>

          <Box
            sx={{
              width: "100%",
              // height: "66%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "0px",
              gap: "24px",
              flex: "none",
              order: 1,
              flexGrow: 1,
            }}
          >
            <CreativeTeamMemberDescription>
              {employee.description}
            </CreativeTeamMemberDescription>
            <Divider style={{ width: "100%" }} />
          </Box>
        </OurStoryTextContainer>
        <OurStorysImageContainer className="OurStory-Image-Container">
          <OurStorysHomeImageNew
            className="OurStoryImage"
            backgroundColor={employee.backgroundColor}
            theme={theme}
          >
            <img
              style={{ width: "100%", height: "auto", borderRadius: "28px" }}
              src={employee.image}
              alt=""
            />
          </OurStorysHomeImageNew>
        </OurStorysImageContainer>
      </Box>
    </Box>
  );
}
