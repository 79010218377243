import React from "react";
import { Routes, Route } from "react-router-dom";
import Features from "./pages/features";
import Home from "./pages/home";
import OurStory from "./pages/ourStory";

export const Router = () => {
  return (
    <Routes>
      {/* <Route path="/profile" element={<PatientProfile />} /> */}
      <Route path="/" element={<Home />} />
      <Route path="/features" element={<Features />} />
      <Route path="/ourstory" element={<OurStory />} />
    </Routes>
  );
};
