import { Box, Chip, Typography, styled } from "@mui/material";

export const HowItWorksContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "4.5rem 0rem",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("lg")]: {
    padding: "3.375rem 0rem",
  },
  [theme.breakpoints.down("md")]: {
    padding: "3rem 0rem",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "3rem 0rem",
  },
}));
export const HowItWorksContentContainer = styled(Box)(({ theme }) => ({
  width: "75.03%",
  padding: "0rem 2rem",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("lg")]: {
    width: "87.09%",
  },
  //For 960 and lower
  [theme.breakpoints.down("md")]: {
    width: "91.66%",
    padding: "0rem",
    justifyContent: "center",
  },

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    padding: "0rem 1rem",
    width: "auto",
    alignSelf: "stretch",
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0rem",
  },
}));

export const HowItWorksChip = styled(Chip)(({ theme }) => ({
  width: "max-content",
  padding: "0.25rem 1rem",
  marginBottom: "1.5rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.primary.main,
  fontSize: "1rem",
  fontWeight: "700",
  borderRadius: "3.125rem",
  backgroundColor: "rgba(30, 66, 95, 0.10)",
  boxSizing: "border-box",
}));

export const HowItWorksTitle = styled(Typography)(({ theme }) => ({
  width: "50%",
  color: theme.palette.primary.main,
  fontSize: "2.625rem",
  fontWeight: "700",
  lineHeight: "125%",
  marginBottom: "3rem",
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
  [theme.breakpoints.down("md")]: {
    width: "70%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const HowItWorksSlides = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "0.75rem",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
  },
}));

export const HowItWorkSlide = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const SlideCount = styled(Typography)(({ theme }) => ({
  padding: "2.1rem",
  fontSize: "2.5rem",
  fontWeight: "700",
  lineHeight: "150%",
  color: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",

  [theme.breakpoints.down("sm")]: {
    margin: "0rem -1rem",
    justifyContent: "unset",
  },
}));

export const SlideBody = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "1rem 1.5rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
  boxShadow: "-40px 40px 80px 0px rgba(145, 158, 171, 0.16)",
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    padding: "1.5rem",
  },
}));

export const SlideBodyTitle = styled(Typography)(({ theme }) => ({
  width: "100%",
  color: "#7F56D9",
  fontSize: "1.5rem",
  fontWeight: "700",
  lineHeight: "1.875rem",
}));

export const SlideBodySubtitle = styled(Typography)(({ theme }) => ({
  width: "100%",
  color: "#475467",
  fontSize: "1.25rem",
  fontWeight: "500",
  lineHeight: "150%",
}));
