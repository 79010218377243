import React, { useState } from "react";

export interface INavItem {
  id: number;
  title: string;
  url: string;
}

export interface INavDrawer {
  id: number;
  heading: string;
  logo: string;
  items: INavItem[];
}

const useNav = () => {
  const [showNav, setShowNav] = useState<boolean>(false);
  const [activeNav, setActiveNav] = useState<number>(1);

  const navDrawer: INavDrawer[] = [
    {
      id: 1,
      heading: "Sections",
      logo: "",
      items: [
        {
          id: 1291,
          title: "What We Do",
          url: "what-we-do-section",
        },
        {
          id: 1292,
          title: "Features",
          url: "feature-section",
        },
        {
          id: 1293,
          title: "The BrightVista Difference",
          url: "bv-difference",
        },
        {
          id: 1295,
          title: "Benefits",
          url: "benefits",
        },
        {
          id: 1296,
          title: "How It Works",
          url: "howItWorks",
        },
        {
          id: 1294,
          title: "About Us",
          url: "aboutUS",
        },
      ],
    },
  ];

  const handleToggleNav = (bool: boolean) => {
    setShowNav(bool);
  };

  const handleActiveNav = (num: number) => {
    setActiveNav(num);
  };
  return {
    showNav,
    activeNav,
    navDrawer,
    handleActiveNav,
    handleToggleNav,
  };
};

export default useNav;
