import { SvgIcon, SvgIconProps } from "@mui/material";

export const FeaturedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_1630_84107)">
        <rect x="2" y="1" width="48" height="48" fill="white" />
        <path
          d="M18.5 34.5L22.5 30.5M24.5 30.5L28.5 34.5M23.5 30.5L23.5 35.5"
          stroke="#2A353D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M16 24.875C16 22.8126 16 21.7814 17.0251 21.1407C18.0503 20.5 19.7002 20.5 23 20.5H24C27.2998 20.5 28.9497 20.5 29.9749 21.1407C31 21.7814 31 22.8126 31 24.875V26.125C31 28.1874 31 29.2186 29.9749 29.8593C28.9497 30.5 27.2998 30.5 24 30.5H23C19.7002 30.5 18.0503 30.5 17.0251 29.8593C16 29.2186 16 28.1874 16 26.125V24.875Z"
          stroke="#2A353D"
          strokeWidth="1.5"
          fill="none"
        />
        <path
          d="M31 23.2495L31.1259 23.174C33.2417 21.9043 34.2996 21.2695 35.1498 21.5761C36 21.8826 36 22.8988 36 24.9312V26.0685C36 28.1009 36 29.1171 35.1498 29.4236C34.2996 29.7301 33.2417 29.0953 31.1259 27.8257L31 27.7501"
          stroke="#2A353D"
          strokeWidth="1.5"
          strokeLinecap="round"
          fill="none"
        />
        <circle
          cx="26.5"
          cy="18"
          r="2.5"
          stroke="#2A353D"
          strokeWidth="1.5"
          fill="none"
        />
        <circle
          cx="21"
          cy="17.5"
          r="3"
          stroke="#2A353D"
          strokeWidth="1.5"
          fill="none"
        />
        <rect
          x="2.5"
          y="1.5"
          width="47"
          height="47"
          stroke="#CBD2E0"
          fill="none"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1630_84107"
          x="0"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1630_84107"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1630_84107"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  );
};
