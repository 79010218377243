import { Box } from "@mui/system";
import {
  FeatureCardContainer,
  FeatureCardDescription,
  FeatureCardIcon,
  FeatureCardImage,
  FeatureCardImageContainer,
  FeatureCardTextContainer,
  FeatureCardTitle,
} from "../../styles/features";
import RequestDemo from "../requestDemo";
import { useTheme } from "@mui/material";

interface feature {
  id: number;
  header: string;
  description: string[];
  icon: string;
  image: string;
}

interface FeatureCardProps {
  feature: feature;
  index: number;
}

export default function FeatureCard({ feature, index }: FeatureCardProps) {
  const theme = useTheme();
  return (
    <>
      <FeatureCardContainer index={index}>
        <FeatureCardTextContainer className="" index={index}>
          {/* <FeatureCardIcon src={feature.icon} /> */}
          <FeatureCardTitle>{feature.header}</FeatureCardTitle>

          <ul
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              alignSelf: "stretch",
              gap: "1rem",
            }}
          >
            {feature?.description?.map((desc: string) => (
              <li key={desc}>
                <FeatureCardDescription>{desc}</FeatureCardDescription>
              </li>
            ))}
          </ul>
        </FeatureCardTextContainer>
        <FeatureCardImageContainer className="" index={index}>
          <FeatureCardImage theme={theme} index={index} src={feature.image} />
        </FeatureCardImageContainer>
      </FeatureCardContainer>
      {index === 4 && <RequestDemo />}
    </>
  );
}
