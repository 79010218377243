import { Box, ListItemButton } from "@mui/material";
import {
  AppBarButton,
  AppBarContainer,
  AppBarHeader,
  NavList,
  NavListItemText,
} from "../../styles/appBar";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import useContact from "../../hooks/useContact";
import { Colors } from "../../styles/theme";
import ContactUsPopup from "../contactUsPopup";

interface AppBarProps {
  matches: boolean;
}

interface ScrollerProps {
  targetId: string;
  children: React.ReactNode;
}

function Scroller({ targetId, children }: ScrollerProps) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const targetElement = document.getElementById(targetId);
      console.log(targetElement);
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        // console.log(rect);
        // console.log(rect.top);
        // console.log(rect.bottom);
        // console.log(rect.top >= 0);
        // console.log(window.innerHeight);
        // console.log(rect.bottom <= window.innerHeight);
        const isInView =
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2;
        setIsActive(isInView);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [targetId]);

  const handleClick = () => {
    console.log("clicked");
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <ListItemButton
      onClick={handleClick}
      className={isActive ? "bv-active-section" : ""}
      sx={{
        padding: "0.5rem 0rem",
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      <NavListItemText
        primary={children}
        sx={{
          "& span": {
            color: isActive ? `${Colors.primary}` : "#4A5468",
            fontWeight: isActive ? 700 : 600,
          },
        }}
      />
    </ListItemButton>
  );
}

export default function AppBarDeskTop({ matches }: AppBarProps) {
  const navigate = useNavigate();
  const {
    showContactPopup,
    contactUsPopupStatus,
    handleContactPopup,
    handleSendContactInfo,
  } = useContact();

  const handleLogoClick = () => {
    navigate("/");
  };
  return (
    <AppBarContainer className="AppBar-Container" position="sticky">
      <AppBarHeader src="/images/BVLogo.svg" onClick={handleLogoClick} />

      <NavList type="row">
        <Scroller targetId="what-we-do-section">What We Do</Scroller>
        <Scroller targetId="feature-section">Features</Scroller>
        <Scroller targetId="bv-difference">The BrightVista Difference</Scroller>
        <Scroller targetId="benefits">Benefits</Scroller>
        <Scroller targetId="howItWorks"> How It Works</Scroller>
        <Scroller targetId="aboutUS">About Us</Scroller>
        <AppBarButton
          variant="contained"
          onClick={() => handleContactPopup(true)}
        >
          Contact Us
        </AppBarButton>
      </NavList>

      <ContactUsPopup
        status={contactUsPopupStatus}
        showContactPopup={showContactPopup}
        handleContactPopup={handleContactPopup}
        handleSendContactInfo={handleSendContactInfo}
      />
    </AppBarContainer>
  );
}
