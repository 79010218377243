import { Box, useTheme, useMediaQuery } from "@mui/material";
import FeaturesHomeInfoPagesNew from "../../components/feature/featuresHomeInfoPagesNew";
import OurStoryCard from "../../components/ourStory/ourStoryCard";
import { team } from "../../data/ourStory";
import { FeaturesHomeHeaderContent } from "../../styles/features";
import {
  CreativeTeamHeader,
  CreativeTeamNeutral,
  OurStoryContentContainer,
  OurStoryContentHeaderContainer,
  OurStoryHeader,
  OurStoryHeaderTitle,
  OurStoryMainContentHeaderContainer,
  OurStoryTeamContainerStack,
  OurStorysHeaderImageContainer,
} from "../../styles/ourStory";
import { CreativeTeamMobile } from "../../components/ourStory/creativeTeamMobilePage";

export default function OurStory() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesTab = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box
      className="OurStory-Container"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "space-between",
        overflowX: "hidden",
      }}
    >
      <OurStoryContentContainer>
        <OurStoryContentHeaderContainer className="Header-Container">
          <OurStoryHeader>{`About The Company`}</OurStoryHeader>
          <OurStoryHeaderTitle>{`A Dedicated Solution For Healthcare Providers`}</OurStoryHeaderTitle>
          <FeaturesHomeHeaderContent>{`Designed to provide a comprehensive, customizable solution that improves patient engagement, streamlines operations, and enhances overall care delivery.`}</FeaturesHomeHeaderContent>
        </OurStoryContentHeaderContainer>

        <OurStorysHeaderImageContainer className="Header-Image-Container">
          <img
            src={
              matches
                ? "/images/features/OtherPage_Banner.png"
                : matchesTab
                ? "/images/features/OtherPage_Banner_Tab.png"
                : "/images/features/OtherPage_Banner_Mobile.png"
            }
          />
        </OurStorysHeaderImageContainer>
      </OurStoryContentContainer>
      <OurStoryMainContentHeaderContainer>
        <CreativeTeamNeutral>{`Executive Team`}</CreativeTeamNeutral>
        <CreativeTeamHeader>{`Experienced in Healthcare and Technology`}</CreativeTeamHeader>
      </OurStoryMainContentHeaderContainer>
      {matches ? (
        <OurStoryTeamContainerStack spacing={10}>
          {team.map((employee, index) => (
            <OurStoryCard
              key={employee.id}
              employee={employee}
              width="100%"
              index={index}
            />
          ))}
        </OurStoryTeamContainerStack>
      ) : (
        <CreativeTeamMobile />
      )}
    </Box>
  );
}
