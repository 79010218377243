import {
  Box,
  BoxProps,
  styled,
  Tabs,
  Tab,
  Typography,
  Button,
  Pagination,
  Stack,
  Theme,
} from "@mui/material";
import { relative } from "path";
import { Colors } from "../theme";

interface FeatureCardContainerProps extends BoxProps {
  index: number;
}

interface FeatureCardTextProps extends BoxProps {
  index: number;
}

export const FeaturesHomeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginTop: "129px",

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    marginTop: "110px",
  },
}));

export const FeaturesHomeContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  gap: "40px",

  width: "62.5%",
  height: "780px",

  //For 1439 and lower
  [theme.breakpoints.down("xl")]: {
    width: "82.4%",
    height: "734px",
  },

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    gap: "8px",
    width: "46.1%",
    height: "940px",
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    width: "91.3%",
    height: "666px",
  },
}));

// export const FeaturesHomeHeaderContainer = styled(Box)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "flex-start",
//   padding: "0px",
//   gap: "12px",
//   width: "60%",
//   flex: "none",
//   order: 0,
//   flexGrow: 0,
//   boxSizing: "border-box",

//   //For 1239 and lower
//   [theme.breakpoints.down("lg")]: {},

//   //For 904 and lower
//   [theme.breakpoints.down("md")]: {
//     width: "100%",
//   },
// }));

export const FeaturesHomeHeaderTitleConatiner = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  gap: "12px",
  width: "100%", //588
  order: 1,

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    width: "100%", // 417
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    alignItems: "flex-start",
    width: "100%", // 328/359
  },
}));

export const FeaturesHomeHeaderTitle = styled(FeaturesHomeHeaderTitleConatiner)(
  ({ theme }) => ({
    fontSize: "48px",
    lineHeight: "125%",
    /* or 50px */
    textTransform: "none",
    height: "60px",

    //For 1239 and lower
    [theme.breakpoints.down("lg")]: {
      height: "50px",
      fontSize: "40px",
      alignItems: "center",
    },

    //For 904 and lower
    [theme.breakpoints.down("md")]: {
      height: "45px",
      fontSize: "36px",
      alignItems: "flex-start",
    },

    display: "flex",
    color: Colors.primary,
  })
);

export const FeaturesHomeTabsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  gap: "56px",

  width: "100%", //1200
  height: "68.2%", // 501/734

  flex: "none",
  order: 1,
  flexGrow: 0,

  // //For 1439 and lower
  // [theme.breakpoints.down("xl")]: {
  //   width: "82.4%", //1126/1366
  // },

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    gap: "97px",
    // width: "55%", // 931/905 using 500 ??
    height: "75.4%", //"815+27+18 /1140px" (including page numbers),
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    gap: "40px",
    // width: "92%", // 328/359
    height: "70.9%", //730+27+18/ 1092(including page numbers)
  },
}));

export const FeaturesHomePagesConatiner = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0px",
  gap: "30px",
  width: "100%",
  height: "70%",

  flex: "none",
  order: 0,
  flexGrow: 0,

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    alignItems: "flex-start",
    height: "55.5%", //730+27+18/ 1092(including page numbers)
    gap: "30px",
  },
}));

export const FeaturesHomeTabs = styled(Tabs)({
  // borderBottom: '1px solid #e8e8e8',
  " .MuiTabs-flexContainer": {
    justifyContent: "space-around",
  },

  "& .MuiTabs-indicator": {
    display: "none",
  },
});

export const FeaturesHomeTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  color: Colors.primary,
  gap: "8px",
  padding: "0px",
  margin: "4px",
  width: "18%",
  minHeight: "24px",

  "&.Mui-selected": {
    backgroundColor: Colors.primary,
    color: Colors.white,
    borderRadius: "8px",
    width: "24%",
    height: "40px",
    padding: "8px 24px",
    gap: "10px",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

export const FeaturesHomePage = styled(Pagination)(({ theme }) => ({
  ".MuiPagination-root": {
    "& .Mui-selected": {
      color: Colors.primary,
      backgroundColor: "transparent",
      border: "none",

      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  " .MuiPaginationItem-page": {
    color: Colors.text_Neutral,
    opacity: 0.9,
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "150%",
  },

  "& .Mui-selected": {
    color: Colors.primary,
    backgroundColor: "transparent",
    border: "none",

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export const FeatureCardIcon = styled(`img`)(({ theme }) => ({
  width: "72px",
  height: "72px",

  flex: "none",
  order: 0,
  flexGrow: 0,

  // //For 1439 and lower
  // [theme.breakpoints.down("xl")]: {
  //   left: "211px",
  //   top: "591px",
  // },

  //For 1239 and lower
  // [theme.breakpoints.down("lg")]: {},

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    alignSelf: "flex-start",
  },
}));

export const MoreDetailsButton = styled(Button)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 32px",
  gap: "8px",
  height: "14%",
  width: "47%",
  borderRadius: "10px",

  flex: "none",
  order: 1,
  flexGrow: 0,

  textTransform: "none",
  fontStyle: "normal",
  fontSize: "18px",
  lineHeight: "125%",
  textAlign: "center",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "18%",
    alignSelf: "stretch",
    order: 3,
  },
}));

export const FeatureCardTitle = styled(Typography)(({ theme }) => ({
  // backgroundColor: "#efefef",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "2rem",
  lineHeight: "125%",
  /* or 28px */

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.75rem",
  },

  color: theme.palette.primary.main,
}));

export const FeatureCardDescription = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "1.25rem",
  lineHeight: "135%",

  alignSelf: "stretch",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    fontSize: "1rem",
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    fontSize: "1.25rem",
  },

  color: Colors.text_secondary,
}));

export const FeaturesHeaderPages = styled(Button)(
  ({ theme, selected }: { theme: Theme; selected: boolean }) => ({
    fontStyle: "normal",
    minWidth: "unset",
    fontSize: "18px",
    lineHeight: "150%",

    color: selected ? Colors.primary : Colors.text_Neutral,
    opacity: 0.9,
    flex: "none",
    order: 0,
    flexGrow: 0,
  })
);

export const FeaturesHomePagesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0px",
  gap: "30px",
  width: "100%",

  flex: "none",
  order: 0,
  flexGrow: 0,

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    alignItems: "flex-start",
    gap: "30px",
  },
}));

export const FeatureCardTitleNew = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "125%",
  /* or 28px */

  width: "100%",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    textAlign: "center",
  },

  //For 904 and lower
  [theme.breakpoints.down("md")]: {
    textAlign: "unset",
  },

  color: Colors.primary,

  flex: "none",
  order: 1,
  flexGrow: 0,
  alignSelf: "stretch",
}));

export const FeaturesContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "4.5rem 0rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxSizing: "border-box",
  gap: "4rem",
  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    padding: "3.375rem 0rem",
  },

  //For 960 and lower
  [theme.breakpoints.down("md")]: {
    padding: "3rem 0rem",
  },
}));

export const FeaturesHeadingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "75.03%",
  padding: "0rem 2rem",
  boxSizing: "border-box",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "2rem",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    width: "87.09%",
  },

  //For 960 and lower
  [theme.breakpoints.down("md")]: {
    width: "91.66%",
    justifyContent: "center",
  },

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    padding: "0rem 1rem",
    width: "auto",
    alignSelf: "stretch",
  },
}));

export const FeaturesHomeHeaderNeutral = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "1rem",
  lineHeight: "normal",

  width: "max-content",
  padding: "0.25rem 1rem",
  borderRadius: "3.125rem",
  backgroundColor: "rgba(30, 66, 95, 0.10)",
  color: Colors.primary,
  boxSizing: "border-box",
}));

export const FeaturesHomeHeaderTitleNew = styled(Typography)(({ theme }) => ({
  fontSize: "3rem",
  lineHeight: "125%",
  fontWeight: 800,
  fontStyle: "normal",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    fontSize: "2.625rem",
  },

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.25rem",
  },

  color: Colors.primary,
}));

export const FeaturesHomeHeadTitle = styled(FeaturesHomeHeaderTitleNew)(
  ({ theme }) => ({
    fontSize: "62px",
  })
);

export const FeaturesHomeHeaderContent = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1.5rem",
  lineHeight: "135%",
  width: "69.58%",
  fontStyle: "normal",

  //For 1239 and lower
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.25rem",
    width: "65.64%",
  },

  //For 960 and lower
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
    width: "72.05%",
  },

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.25rem",
    width: "auto",
    alignSelf: "stretch",
  },

  color: "#1E425F",
}));

export const FeatureCardStack = styled(Stack)(({ theme }) => ({
  width: "100%",
  alignItems: "center",
  padding: "2.75rem 0rem 6rem 0rem",
  gap: "4.5rem",

  //For 499 and lower
  [theme.breakpoints.down("sm")]: {
    padding: "4rem 0rem",
  },
}));

export const FeatureCardContainer = styled(Box)<FeatureCardContainerProps>(
  ({ theme, index }) => ({
    width: "75.03%",
    boxSizing: "border-box",
    display: "flex",
    padding: "0rem 2rem",
    gap: "5.2%",
    // border: "3px solid black",
    justifyContent: index % 2 === 0 ? "flex-start" : "flex-end",
    alignItems: "center",

    [theme.breakpoints.down("lg")]: {
      width: "87.09%",
      gap: "2.58%",
    },
    [theme.breakpoints.down("md")]: {
      width: "91.66%",
      padding: "0rem ",
    },

    [theme.breakpoints.down("sm")]: {
      width: "91.4%",
      padding: "0rem ",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-Start",
    },
  })
);

export const FeatureCardTextContainer = styled(Box)<FeatureCardTextProps>(
  ({ theme, index }) => ({
    width: "46%",
    // minWidth: "35rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "1.5rem",
    alignSelf: index % 2 === 0 ? "stretch" : "unset",
    flexShrink: 0,
    // border: "1px solid blue",
    order: index % 2 === 0 ? 1 : 2,

    [theme.breakpoints.down("lg")]: {
      width: "36.72%",
    },

    [theme.breakpoints.down("md")]: {
      width: "42.2%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      order: 1,
    },
  })
);

export const FeatureCardImageContainer = styled(Box)<FeatureCardTextProps>(
  ({ theme, index }) => ({
    display: "flex",
    justifyContent: index % 2 === 0 ? "flex-end" : "unset",
    alignItems: "center",
    flex: "1 0 0",
    // height: "512px",
    order: 1,

    //For 1239 and lower
    [theme.breakpoints.down("lg")]: {
      // height: "28.5rem",
    },
    [theme.breakpoints.down("md")]: {
      // height: "21.4375rem",
      paddingLeft: "0px",
    },

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",

      // width: "auto",
      // height: "17rem",
      padding: "0.70831rem 0rem 0.66669rem 0rem",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

export const FeatureCardImage = styled(`img`)(
  ({ theme, index }: { theme: Theme; index: number }) => ({
    width: "129%", // for overflow - as per intuition
    height: "auto",
    position: "relative",
    left: index % 2 === 0 ? "29%" : "unset",
    right: index % 2 === 0 ? "unset" : "29%",
    // minWidth: "768px",
    maxHeight: "512px", // for responsive - fix as per figma

    //For 1239 and lower
    [theme.breakpoints.down("lg")]: {
      width: "111%",
      left: index % 2 === 0 ? "6%" : "unset",
      right: index % 2 === 0 ? "unset" : "6%",
      // minWidth: "684px",
      maxHeight: "456px",
    },
    [theme.breakpoints.down("md")]: {
      width: "106%",
      left: index % 2 === 0 ? "2%" : "unset",
      right: index % 2 === 0 ? "unset" : "2%",
      maxHeight: "344px",
      // minWidth: "515px",
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "100%",
      maxHeight: "250px",
    },
  })
);
