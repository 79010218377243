import { Box, Chip, Typography, styled } from "@mui/material";
import { Colors } from "../theme";

export const BenefitsSection = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  // minHeight: "60rem",
  padding: "4.5rem 0rem",
  boxSizing: "border-box",
  gap: "10px",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    padding: "3.375rem 0rem",
    // minHeight: "53.3125rem",
  },

  [theme.breakpoints.down("md")]: {
    // minHeight: "50.7rem",
    padding: "3rem 0rem",
  },

  // [theme.breakpoints.down("sm")]: {
  //   // minHeight: "100%",
  //   padding: "4rem 4.2%",
  // },
}));

export const BenifitsContainer = styled(Box)(({ theme }) => ({
  width: "75.03%",
  height: "100%",
  padding: "0rem 2.2%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "5%",
  boxSizing: "border-box",

  [theme.breakpoints.down("lg")]: {
    width: "87.09%",
    padding: "0% 3%",
    gap: "3%",
  },

  [theme.breakpoints.down("md")]: {
    width: "91.66%",
    padding: "0% ",
    gap: "3.6%",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "0%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "space-between",
    gap: "2.75rem",
  },
}));

export const BenefitsInfoContainer = styled(Box)(({ theme }) => ({
  width: "31.6%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "1.25rem",
  boxSizing: "border-box",

  [theme.breakpoints.down("lg")]: {
    width: "35.45%",
  },

  [theme.breakpoints.down("md")]: {
    width: "42.4%",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const BenefitsChip = styled(Chip)(({ theme }) => ({
  width: "max-content",
  padding: "0.25rem 1rem",
  color: Colors.primary,
  fontSize: "1rem",
  fontWeight: "700",
  boxSizing: "border-box",
}));

export const BenefitsTitle = styled(Typography)(({ theme }) => ({
  width: "100%",
  color: Colors.primary,
  fontSize: "2.625rem",
  fontWeight: "700",
  lineHeight: "104.762%",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.25rem",
    lineHeight: "125%",
  },
}));

export const BenefitsDescription = styled(Typography)(({ theme }) => ({
  width: "100%",
  color: Colors.gray_600,
  fontSize: "1.25rem",
  fontWeight: "500",
  lineHeight: "135%",
}));

export const BenefitsTemplate = styled(Typography)(({ theme }) => ({
  width: "100%",
  backgroundColor: Colors.neutral_100,
  borderLeft: "4px solid #378DBD",
  padding: "0.75rem",
  color: Colors.gray_600,
  fontSize: "1.25rem",
  fontWeight: "500",
  lineHeight: "135%",
  boxSizing: "border-box",
}));

export const BenefitsImageContainer = styled(Box)(({ theme }) => ({
  width: "63.18%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",

  [theme.breakpoints.down("lg")]: {
    width: "66.5%",
  },

  [theme.breakpoints.down("md")]: {
    width: "54.2%",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const BenefitsImage = styled("img")(({ theme }) => ({
  width: "100%",
  objectFit: "contain",

  [theme.breakpoints.down("lg")]: {
    maxHeight: "40.125rem",
  },

  [theme.breakpoints.down("md")]: {
    maxHeight: "29.7rem",
  },

  [theme.breakpoints.down("sm")]: {
    maxHeight: "21.43rem",
  },
}));
