import { Box, Button, useTheme } from "@mui/material";
import {
  OurStoryHomeHeaderNeutral,
  OurStoryHomeHeaderTitle,
  OurStoryHomeMoreDetailsButton,
  OurStoryHomeText,
  OurStorysHomeContentContainer,
  OurStorysHomeHeaderContainer,
  OurStorysHomeImage,
  OurStorysHomeImageContainer,
  OurStorysHomeContent,
  OurStorysHomeHeaderContent,
  OurStoryHomeHeaderNeutralContainer,
  OurStorysHomeImageContent,
  OurStorysHomeImageMember,
  OurStorysHomeMemberDetails,
  OurStorysHomeMemberText,
  OurStorysHomeMemberMoreInfo,
  OurStorysHomeMemberName,
  OurStorysHomeMemberRole,
} from "../../styles/ourStory";

import { OurStoryHomePageContent } from "../../data/ourStory";
import { Colors } from "../../styles/theme";
import { Link } from "react-router-dom";

export default function OurStoryHomePage() {
  const theme = useTheme();
  return (
    <Box
      className="OurStoryHomePage-Container"
      id={"aboutUS"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "4rem",
        width: "100%",
        boxSizing: "border-box",
        padding: { xl: "4.5rem 0rem", sm: "3rem 2.5rem", xs: "3.375rem 0rem" },
      }}
    >
      <OurStorysHomeContentContainer className="OurStoryHomePage-Content-Container">
        <OurStorysHomeContent className="OurStoryHomePage-Text">
          <OurStorysHomeHeaderContainer>
            <OurStorysHomeHeaderContent>
              <OurStoryHomeHeaderNeutralContainer>
                <OurStoryHomeHeaderNeutral>{`Executive Team`}</OurStoryHomeHeaderNeutral>
              </OurStoryHomeHeaderNeutralContainer>
              <OurStoryHomeHeaderTitle>{`Experienced in Healthcare and Technology`}</OurStoryHomeHeaderTitle>
            </OurStorysHomeHeaderContent>
          </OurStorysHomeHeaderContainer>
          {/* <OurStoryHomeMoreDetailsButton
            component={Link}
            to={"/ourstory"}
            variant="outlined"
          >
            About us
          </OurStoryHomeMoreDetailsButton> */}
        </OurStorysHomeContent>
      </OurStorysHomeContentContainer>

      <OurStorysHomeImageContainer className="OurStoryHomePage-Images">
        <OurStorysHomeImageContent>
          <OurStorysHomeImageMember>
            <OurStorysHomeImage
              className="OurStoryImage"
              backgroundColor={Colors.body_bg}
              theme={theme}
            >
              <img
                style={{ width: "100%", height: "auto" }}
                src="/images/ourStory/Image_1.png"
                alt=""
              />
            </OurStorysHomeImage>
            <OurStorysHomeMemberDetails>
              <OurStorysHomeMemberText>
                <OurStorysHomeMemberName>
                  {"Ilan Sehayek"}
                </OurStorysHomeMemberName>
                <OurStorysHomeMemberRole>{`Chief Executive Officer`}</OurStorysHomeMemberRole>
              </OurStorysHomeMemberText>
              {/* <OurStorysHomeMemberMoreInfo
                component={Link}
                to={"/ourstory"}
                variant="outlined"
              >
                Read More
              </OurStorysHomeMemberMoreInfo> */}
            </OurStorysHomeMemberDetails>
          </OurStorysHomeImageMember>
          {/*  <OurStorysHomeImageMember>
            <OurStorysHomeImage
              className="OurStoryImage"
              backgroundColor={Colors.primary}
              theme={theme}
            >
              <img
                style={{ width: "100%", height: "auto" }}
                src="/images/ourStory/Image_2.png"
                alt=""
              />
            </OurStorysHomeImage>
           <OurStorysHomeMemberDetails>
              <OurStorysHomeMemberText>
                <OurStorysHomeMemberName>
                  {"Stephen Batuello MD"}
                </OurStorysHomeMemberName>
                <OurStorysHomeMemberRole>{`Chief Operating Officer`}</OurStorysHomeMemberRole>
              </OurStorysHomeMemberText>

              {/* <OurStorysHomeMemberMoreInfo
                component={Link}
                to={"/ourstory"}
                variant="outlined"
              >
                Read More
              </OurStorysHomeMemberMoreInfo> 
            </OurStorysHomeMemberDetails>
          </OurStorysHomeImageMember> */}
          <OurStorysHomeImageMember>
            <OurStorysHomeImage
              className="OurStoryImage"
              backgroundColor={Colors.highlight}
              theme={theme}
            >
              <img
                style={{ width: "100%", height: "auto" }}
                src="/images/ourStory/Image_3.png"
                alt=""
              />
            </OurStorysHomeImage>
            <OurStorysHomeMemberDetails>
              <OurStorysHomeMemberText>
                <OurStorysHomeMemberName>
                  {"Andrew Veit MD MBA"}
                </OurStorysHomeMemberName>
                <OurStorysHomeMemberRole>{`Chief Medical Officer`}</OurStorysHomeMemberRole>
              </OurStorysHomeMemberText>
              {/* <OurStorysHomeMemberMoreInfo
                component={Link}
                to={"/ourstory"}
                variant="outlined"
              >
                Read More
              </OurStorysHomeMemberMoreInfo> */}
            </OurStorysHomeMemberDetails>
          </OurStorysHomeImageMember>
        </OurStorysHomeImageContent>
      </OurStorysHomeImageContainer>
    </Box>
  );
}
