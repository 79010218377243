export const features = [
  {
    id: 1,
    header: "Onboarding for New and Existing Patients",
    description: [
      "With our innovative patient self-service onboarding solution, accessing your enterprise has never been easier. Patients can seamlessly connect through the BrightVista gateway, where they conveniently enter their personal, demographic, and insurance information.",
      "Say goodbye to repetitive data entry! BrightVista’s advanced technology takes care of the rest by populating this information across all relevant aspects of your enterprise. Enjoy a streamlined and efficient process, saving time for both your patients and staff, while ensuring accurate and up-to-date information at every touchpoint.",
    ],
    icon: "/images/features/Onboarding_icon.svg",
    image: "/images/features/Onboarding_For_New_And_Existing_Patients.png",
  },
  {
    id: 2,
    header: "Finding the Best Available Provider and Appointment Slot",
    description: [
      "BrightVista takes the hassle out of the searching and introduces you to the right healthcare professional. With us, you have two convenient options: you can explore and search for providers on your own or let BrightVista’s intelligent system do the work for you.",
      "If you prefer to take charge, our user-friendly platform allows you to browse and select from a wide array of providers, making it simple to find the one that best suits your needs. On the other hand, if you’re unsure or want a tailored recommendation, BrightVista can match you with the most suitable provider based on factors such as specialty, appointment reason, and acuity.",
    ],
    icon: "/images/features/Scheduling_icon.svg",
    image:
      "/images/features/Finding_Best_Available_Provider_And_Appointment_Slot.png",
  },
  {
    id: 3,
    header:
      "Managing all your Dependents' Healthcare Needs through a Single Login",
    description: [
      "At BrightVista, we believe in making healthcare management a breeze for you and your loved ones. With our “ Manage your Dependents ” feature, you can effortlessly add your dependent’s information and take charge of their healthcare journey, all within your gateway.",
      "Gone are the days of juggling multiple platforms or struggling to keep track of your dependents’ healthcare needs. BrightVista brings you the convenience of centralized management, ensuring that your dependents benefit from the same exceptional capabilities that you already enjoy.",
    ],
    icon: "/images/features/PracticeManagement_icon.svg",
    image: "/images/features/Managing_all_your_depenednts.png",
  },
  {
    id: 4,
    header: "Self-Service Scheduling Appointments is One Click Away",
    description: [
      "With BrightVista, scheduling appointments becomes a seamless and convenient process. Choose from a personalized list of your preferred providers, ensuring you receive care from those you trust the most. Alternatively, opt for the “ next available “ option to secure an appointment quickly. For those seeking specialized care for a specific problem, our system makes it easy to find the perfect match.",
      "Rest assured that BrightVista’s advanced technology goes beyond mere convenience. We prioritize compliance with both practice and provider protocols, ensuring that the appointment you book is tailored to your unique needs and aligns with the highest standards of care.",
    ],
    icon: "/images/features/EHRIntegration_icon.svg",
    image: "/images/features/Self_Service_Scheduling.png",
  },
  {
    id: 5,
    header:
      "Confirming, Rescheduling, or Cancelling appointments without Phone Calls",
    description: [
      "With our Gateway, you gain full control over your appointments, making it a breeze to review, cancel, and reschedule with real-time updates.",
      "No more waiting on the phone or relying on others to handle your appointments. BrightVista empowers you to conveniently manage your healthcare agenda at your fingertips. Review upcoming appointments, cancel, or reschedule as needed, all in a seamless and user-friendly interface.",
    ],
    icon: "/images/features/Analytics_icon.svg",
    image: "/images/features/Confirming_Rescheduling_Cancelling.png",
  },
  {
    id: 6,
    header: "Verifying Insurance Eligibility before Booking an Appointment",
    description: [
      "At BrightVista, we leverage cutting-edge technology to automate the insurance verification process, minimizing the risk of clerical errors and ensuring precise results.",
      "Gone are the days of uncertainty and lengthy wait times. With our real-time verification system, you receive instant updates on your network status and insurance eligibility. Trust BrightVista to handle the complexities of insurance verification, leaving you with the confidence that your information is obtained accurately and hassle-free.",
    ],
    icon: "",
    image: "/images/features/Verifying_Insurance.png",
  },
  {
    id: 7,
    header: "Receiving a Good Faith Cost Estimate Prior to your Visit",
    description: [
      "Our platform empowers patients with “ good faith estimates ” ensuring they have a clear understanding of their financial responsibility from the outset. Say goodbye to surprise billing and welcome a new era of transparency in healthcare costs.",
      "At BrightVista, we believe that informed patients make better decisions. By providing upfront cost estimates, we help patients navigate their healthcare journey with confidence, eliminating any uncertainty about financial obligations.",
    ],
    icon: "",
    image: "/images/features/Recieving_good_faith.png",
  },
  {
    id: 8,
    header: "Automating Preauthorizations for Medical Services",
    description: [
      "We harness the power of technology to streamline the preauthorization process. With BrightVista, say goodbye to the hours spent on the phone waiting for insurance clearance. Our automated system handles pre-authorizations seamlessly, saving your staff valuable time and effort.",
      "We understand the importance of providing your patients with peace of mind. With our efficient preauthorization process, patients can rest assured that they have insurance clearance well in advance of their appointment. No more fear of last-minute cancellations or insurance denials.",
    ],
    icon: "",
    image: "/images/features/Automating_Preauthorization.png",
  },
  {
    id: 9,
    header:
      "Tracking and Measuring Success across your Patients and Practice Operations",
    description: [
      "At BrightVista, we believe in the transformative value of data-driven insights. That’s why we leverage the cutting-edge Mixpanel platform for data analytics, empowering you with unparalleled customization and configurability in generating reports.",
      "Our analytics and reporting feature allows you to delve deep into your data, gaining valuable insights that drive informed decisions. With Mixpanel’s robust capabilities, you have the flexibility to tailor reports to your specific needs and preferences.",
      "From tracking key performance indicators to measuring the effectiveness of your healthcare strategies, BrightVista analytics and reporting tools provide you with a comprehensive understanding of your enterprise’s performance.",
    ],
    icon: "",
    image:
      "/images/features/Tracking_and_measuring_sucess_across your_patients_and_practice.png",
  },
];
